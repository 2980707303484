.mail-list-tab-view {
  @media screen and (min-width: 991px) {
    height: auto;
    max-height: 90vh;
    min-height: 300px;

    .max-height-90 {
      max-height: 90vh;
    }
    .msg-holder {
      max-height: calc(90vh - 116px);
    }
  }
  .p-tabview-nav {
    width: 100%;
    border: none !important;
  }
  .p-tabview-panels {
    padding: 0 !important;
    border-radius: 0px 0px 16px 16px;
  }
  .p-tabview-nav-content {
    border-radius: 16px 16px 0px 0px;
  }
  .p-unselectable-text {
    width: 50%;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #eff4ff !important;
    border: none !important;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none !important;
  }
  .p-tabview-nav-link {
    width: 100%;
    text-align: center;

    .p-tabview-title {
      width: 100%;
    }
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #ffffff !important;
    border: none !important;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link {
    background-color: #eff4ff !important;
    border: none !important;
  }
  .msg-holder {
    border-radius: 0px 0px 16px 16px !important;
  }
}
.cursor-text {
  cursor: text !important;
}
.unread-email {
  h6 {
    font-weight: bold !important;
  }
  span {
    font-weight: bold !important;
  }
}
.email-quill-editor {
  .ql-container {
    min-height: 150px !important;
    max-height: 100% !important;
  }
}
.email-search {
  .p-inputtext {
    width: 100%;
    border-radius: 0%;
  }
  .p-autocomplete {
    display: flex !important;
    width: 100%;
  }
}
.mail-list-box {
  height: 92px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mail-list-box .col-10 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mail-list-box .text-break {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.mail-list-box h6,
.mail-list-box span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
}
.mail-list-outer-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.mail-list-badge {
  position: absolute;
  // background-color: #ff583e !important;
  // color: white !important;
  top: -3px;
  right: 10px;
  margin: 0px;
}
.mail-preview-inner {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.mail-preview-container {
  .gmail_signature {
    margin-top: 35px;
  }
}
.load-more-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.reconcile-modal {
  .case-tooltip {
    width: 60% !important;
  }
}

#caseMappingModal {
  @media (min-width: 3840px) {
    .p-dialog {
      width: 30vw !important;
    }
  }
}
.reconciliation-page {
  .filter-wrap {
    width: fit-content !important;
  }
}
