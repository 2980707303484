@import './mixin.scss';
@import './variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import 'fontawesome/fontawesome.scss';
@import 'fontawesome/solid.scss';
@import 'fontawesome/brands.scss';
@import 'fontawesome/regular.scss';
@import './icon_style.css';

/* Styles for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

html {
  @include h-100;
}

body {
  //font-family: $primary-font;
  font-family: 'AvenirNextR';
  background-color: $background;
  letter-spacing: 0.0025em;
  @include h-100;
  font-size: 14px;
  overscroll-behavior-x: contain;
}
#root {
  @include h-100;
  overflow: auto;
}
.container-login {
  @include h-100;

  button {
    font-weight: 700;
  }
  .p-card {
    width: 40rem;
    box-shadow: none !important;

    .sign-up-form {
      label {
        font-size: $size-12 !important;
        text-transform: uppercase;
        //font-weight: 600;
        color: $black-600 !important;
      }
    }
  }

  .p-card-title {
    font-size: $size-16 !important;
  }
}
.signup-text {
  padding-top: 18px !important;
}

.login-link {
  cursor: pointer;
}

.login-link:hover {
  text-decoration: underline;
}

.container-signup,
.container-newPass {
  @extend .container-login;
  button {
    font-weight: 700;
  }
}

.navbar {
  background-color: $background !important;

  .add-button {
    width: 61px;
    background-color: $background !important;
    display: flex;
    justify-content: center;

    .icon-add {
      margin-right: 4px !important;
    }

    span {
      color: $primary-main !important;

      &::before {
        color: $primary-main !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .icon-notification,
    .icon-add {
      font-size: 20px !important;
    }
    .icon-notification {
      margin-right: 10px;
    }
  }
}

a {
  color: $primary-dark;

  &:hover {
    color: $primary-middle;
  }
}

// --- Typography ---//
h1.title {
  //font-family: $primary-font;
  font-family: 'AvenirNextD';
  font-style: normal;
  // font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 125% */
  letter-spacing: 0.0025em;
  /* black/800 */
  color: $black-800;
  // text-transform: capitalize;
}

.sub-title {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: $black-800;
  font-family: 'AvenirNextD';
}

.text-hint {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: $black-500;
}

.text-primary-main {
  color: $primary-main;

  &::before {
    color: $primary-main;
  }
}

.text-primary-middle {
  color: $primary-middle;
}

.text-primary-dark {
  color: $primary-dark !important;

  &::before {
    color: $primary-dark !important;
  }
}
.w-30 {
  width: 30% !important;
}
.p-medium {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: $black-500 !important;
  font-family: 'AvenirNextM';
}

.caption-bold {
  font-style: normal;
  // font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $black-600;
  margin-bottom: 1rem;
  font-family: 'AvenirNextD';
}

.t-bold {
  font-weight: bold;
}

// --- // Typography ---//

.p-button-primary {
  font-family: $primary-font;
  background-color: $primary-main !important;
  border: 1px solid $primary-main !important;
  border-radius: $size-12 !important;
  padding: 10px 12px !important;
  font-size: 14px !important;
  height: 40px;

  .pi {
    font-size: $size-12;
    color: $white !important;
  }

  &.outline {
    //background-color: $white !important;
    background-color: transparent !important;
    color: $primary-main !important;

    &.p-disabled {
      border-color: $black-200 !important;
      color: $black-200 !important;
      background-color: $black-100 !important;
    }

    & span {
      color: $primary-main !important;
    }
  }

  &.p-disabled {
    background-color: $black-100 !important;
    color: $black-200 !important;
    border: none !important;
  }
}
.ellipsis-1 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-button-sec {
  background-color: transparent !important;
  color: $primary-middle !important;
  border: 1px solid $primary-middle !important;
  height: 32px;
  border-radius: 8px !important;
  line-height: 0 !important;
}
.secondary-button-blue-small {
  border: 1px solid $primary-main;
  border-radius: 12px;
  color: $primary-main;
  padding: 5px 10px;
}
.secondary-button-grey {
  border: 1px solid $black-400 !important;
  background-color: transparent !important;
  border-radius: 12px !important;
  color: $black-400 !important;
  padding: 10px !important;
  height: 40px;
}
.p-button-secondary {
  @extend .p-button-primary;
  height: 32px;
  border-radius: $size-8 !important;
  line-height: 0 !important;

  &.p-button-text {
    color: $white !important;
  }

  &.p-button:hover {
    color: $white !important;
    background: $primary-middle !important;

    span {
      color: $white !important;
    }
  }
}

.p-button {
  &:focus {
    box-shadow: none !important;
  }

  &.button-text {
    @include border-0;
    background-color: transparent;
    color: $primary-main;

    &:hover {
      @include border-0;
      background-color: transparent !important;
      color: $primary-dark !important;
    }
  }

  &.button-bold {
    font-weight: 600;
  }
}

//show elipsis
.text-overflow-ellipsis {
  overflow: hidden !important;
  // display: inline-block;
  // text-align: center;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.pi {
  color: $black-400;

  &.pi-sm {
    font-size: 0.875em;
  }

  &.pi-lg {
    font-size: 1.33em;
  }

  &.pi-2x {
    font-size: 2em;
  }

  &.pi-3x {
    font-size: 3em;
  }
}

.far {
  @extend .pi;
}

.fas {
  @extend .pi;
}

.filter-wrap {
  border-radius: $size-16;
  background-color: $black-100;
}

.input-height {
  height: 40px;
  border: 1px solid $black-100 !important;
}

.input-width {
  width: 215px !important;
  line-height: 1.7;
}

.input-shadow {
  box-shadow: $shadow-sm;
  border-radius: $size-12 !important;

  &.p-inputtext {
    border: 1px solid $black-100 !important;
  }

  .p-inputtext {
    border: 1px solid transparent !important;
    font-size: $size-14 !important;
    border-radius: 12px !important;

    &.disabled {
      border-color: $black-100 !important;
      box-shadow: none !important;
    }

    &:focus {
      //border: 1px solid $primary-main !important;
      border: 1px solid transparent !important;
      box-shadow: $shadow-sm !important;
    }

    &.p-dropdown-label {
      width: 110px;
      font-family: 'AvenirNextR';

      &:hover {
        border-color: $white !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .css-1s2u09g-control {
    height: auto !important;
    border-color: $black-100;
    border-radius: $size-12;
    min-height: 40px;

    &:hover {
      border-color: $black-100;
    }
  }

  .css-1pahdxg-control {
    border: none !important;
    box-shadow: $shadow-sm !important;
    border-radius: $size-8;

    &:hover {
      border: none !important;
      box-shadow: $shadow-sm !important;
      border-radius: $size-8;
    }

    &:active {
      border: none !important;
    }
  }

  .css-1okebmr-indicatorSeparator {
    width: 0 !important;
  }

  .react-tagsinput-tag {
    margin: 2px;
  }

  &:focus-visible {
    outline: none !important;
  }
}
.break-spaces {
  white-space: break-spaces !important;
}
.number-format {
  &:focus-visible {
    outline: none;
  }

  font-family: 'AvenirNextR';
  color: #495057;
  font-size: $size-14 !important;
}

.css-1pahdax-control:hover {
  border: none !important;
}
.p-dropdown-items-wrapper {
  max-height: 250px !important;
}
.p-dropdown {
  @extend .input-shadow;
  border-radius: $size-12 !important;
  border: 1px solid $black-100 !important;
}
.custom-accordion {
  .p-accordion-content {
    background: transparent !important;
  }
  .accordion-wrapper {
    & + * {
      margin-top: 0.5em;
    }
  }

  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .accordion-title {
    font-weight: 600;
    cursor: pointer;
    padding: 0.5em 1em;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ededed;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid currentColor;
      transition: transform 0.3s ease-in-out;
    }

    &:hover,
    &.open {
      color: black;
    }
    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.open::after {
      transform: rotate(180deg);
    }
  }

  .accordion-content {
    padding: 1em 1em;
    background: #fafafa;
  }
}

.p-dropdown-panel {
  padding: $size-8 !important;
  border-radius: $size-12 !important;

  // top: 132px!important;
  .p-dropdown-items {
    & li {
      font-size: $size-14;
      padding: 5px !important;
      background-color: #fafbfe !important;
      border-radius: 10px !important;
      margin: 5px 0 !important;

      .p-dropdown-item {
        &.p-highlight {
          background-color: transparent !important;
        }
      }
    }
  }

  .p-dropdown-header {
    padding: 10px 0 !important;
    background: none;
  }
}

.p-inputwrapper {
  border: 1px solid $black-100;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
  padding: 0.25rem 0.5rem !important;
}

.p-overlaypanel {
  padding: $size-8 !important;
  border-radius: $size-12 !important;

  .p-overlaypanel-content {
    padding: 0 !important;
    font-size: $size-14;

    & div {
      //background-color: #fafbfe;
      border-radius: 10px;
      margin: 5px 0;
      padding: 5px 10px;
    }
  }

  ::after {
    display: block;
  }
}

.p-multiselect-panel {
  @extend .p-dropdown-panel;

  .p-inputtext {
    @include rounded-12;
    box-shadow: $shadow-sm;
    padding: 0.3rem;
  }
}

.p-column-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: 0.01em;
  color: $black-500 !important;
}

.p-sortable-column-icon {
  @extend .p-column-title;
}

.p-sortable-column-icon {
  &.pi {
    font-size: $size-12;
  }
}

.p-datatable {
  .p-datatable-tbody {
    tr {
      font-size: $size-14 !important;
      background: $background !important;

      td {
        border: none !important;
        word-break: break-all;
        //vertical-align: baseline;
        //padding: 10px !important;
        // span,a{
        //   display: -webkit-box;
        //   -webkit-line-clamp: 2; /* Number of lines to show */
        //   -webkit-box-orient: vertical;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        // }
        p {
          margin: 0 !important;
        }
      }
    }

    .p-row-odd {
      background: $white !important;
    }

    .p-avatar {
      width: 24px !important;
      height: 24px !important;
      min-width: 24px !important;
      display: inline-block;
      //display: flex;
      text-align: center;
      position: relative;
      bottom: 0px;
      font-size: $size-12 !important;
      line-height: 2;
      border-radius: $size-8 !important;

      //top: -2px;
      img {
        border-radius: $size-8 !important;
      }
    }
  }
}
.mange-colum-modal {
  .p-datatable .p-datatable-tbody .p-avatar-circle {
    border-radius: 50% !important;
    margin-left: -0.7rem !important;
  }
  .p-datatable .p-datatable-tbody .p-avatar {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  .p-datatable .p-datatable-tbody .p-avatar img {
    border-radius: 50% !important;
  }
}
.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: auto;
  z-index: 99999;

  .p-progress-spinner {
    width: 50px;
    height: 50px;
  }
}

.spinner-small {
  //position: absolute;
  left: 3px;
  top: 0px;
  margin: auto;
  z-index: 99999;

  .p-progress-spinner {
    width: 28px;
    height: 28px;
  }
}

// .p-tag{
//   background-color:#F8955D ;
// }
.case-status-tag {
  background-color: #f8955d !important;
}
.blue-tag {
  background-color: #eff4ff;
  padding: 4px 8px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.blue-tag-value {
  color: #2655b9;
  font-size: 12px;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}
.green-tag {
  background-color: #edfaef !important;
  padding: 4px 8px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.green-tag-value {
  color: #4ba457 !important;
  font-size: 12px;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}
.d-status-tags {
  width: 80px;
}
.tag-cases {
  background-color: $black-100 !important;
  border-radius: $size-8 !important;
  padding: 4px 8px !important;

  .p-tag-value {
    color: $black-900;
    font-size: 12px;
  }
}

.Ptag-case {
  padding: $size-8 !important;
  color: $black-700 !important;
}

.tag,
.p-tag,
.p-autocomplete-token {
  @extend .tag-cases;
  color: $black-700;
  font-size: 12px;
  font-family: 'AvenirNextM';
}

.p-component {
  .p-tag-value {
    color: $tag;
    font-size: 12px;
    font-family: 'AvenirNextM';
    font-weight: 500;
  }

  @include primary-font;
  font-size: $size-14 !important;

  .form-label {
    font-family: 'AvenirNextM';
    color: $black-500 !important;
    margin-bottom: 0 !important;
  }
}

.resolution-tag {
  .p-tag {
    background-color: $primary-bg !important;

    span {
      color: $primary-dark !important;
      font-family: 'AvenirNextR';
    }
  }
}

.taging {
  .p-chips-token {
    @extend .tag-case;
    margin: $size-4 0;

    .pi-times-circle {
      font-size: $size-12;
    }
  }
}

.p-component-overlay {
  // background-color:rgba(221, 223, 229, 0.5)!important;
  // background-color:rgba(162, 166, 178, 0.59) !important;
  background: url(../images/bg_overlay.gif);
  // background: linear-gradient(0deg, , rgba(134, 137, 152, 0.25)), url(02.01.01. Cases list v.2.jpg);
  // background-image: linear-gradient(0deg, rgba(134, 137, 152, 0.103), rgba(134, 137, 152, 0.082));
  // filter: blur(8px);
  // filter: blur(4px);
  // background: #868998;
  // opacity: 25%;
  // filter: blur(4px);
  // -o-filter: blur(4px);
  // -ms-filter: blur(4px);
  // -moz-filter: blur(4px);
  // -webkit-filter: blur(4px);
  // &.p-component-overlay-enter{
  //   animation:none;
  // }
}

.p-sidebar-right {
  // z-index: 1200 !important;
  border-radius: $size-12 0 0 $size-12;
  background-color: #fafbfe;
  box-shadow: 2px 4px 4px rgba(19, 55, 89, 0.08), 0px 32px 8px rgba(19, 77, 89, 0.08);

  &.p-sidebar-full {
    border-radius: 0;
    z-index: 99;
  }
}

.rounded-8 {
  @include rounded-8;
}

.rounded-12 {
  @include rounded-12;
}

.icon-box {
  @include rounded-8;
  border: 1px solid $primary-middle;
  padding: 4px;
}

.p-dialog {
  height: auto !important;
  box-shadow: $shadow-md !important;

  .p-dialog-content,
  .p-dialog-footer {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .p-dialog-header {
    padding-right: 40px !important;
  }
}

.p-dialog-mask {
  background-color: #86899840 !important;
}

.rounded-radius {
  .p-dialog-header {
    border-top-right-radius: $size-12 !important;
    border-top-left-radius: $size-12 !important;
  }

  .p-dialog-footer {
    border-bottom-right-radius: $size-12 !important;
    border-bottom-left-radius: $size-12 !important;
  }
}

.shadow-small {
  @include shadow;
  box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
  border-radius: $size-12 !important;
}

.shadow-middle {
  @include shadow;
  box-shadow: 0px 2px 6px rgba(103, 122, 142, 0.24) !important;

  &.p-card {
    .p-card-content {
      padding: 0;
    }
  }
}

.p-shadow-middle {
  box-shadow: $shadow-md !important;
}

.shadow-big {
  @include shadow;
  box-shadow: 0px 4px 12px rgba(103, 122, 142, 0.24) !important;
}

.p-sidebar {
  background-color: $background !important;

  .p-sidebar-header {
    justify-content: space-between !important;
  }
}

.timemanager-day-boxes {
  // width: 80px;
  // height: 50px;
  background-color: white;
}

.timemanager-day-grey-box {
  // width: 80px;
  // height: 35px;
  //background-color: #606060;
  border: 1px solid $primary-main;
  color: $primary-main;
  border-radius: $size-8;
}

.time-cal {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 2;
}

.danger-text {
  color: red;
}

.cases-steps {
  .p-steps-item {
    justify-content: start;

    &::before {
      height: 12px;
      border: 1px solid $white !important;
      background-color: $success-bg;
      @include rounded-8;
    }

    &.p-highlight {
      &::before {
        background-color: $success;
      }

      .p-menuitem-link {
        .p-steps-number {
          background-color: $success !important;
        }
      }
    }

    .p-menuitem-link {
      flex-direction: row;
      margin-top: 18px;
      font-size: 12px !important;
      background: none !important;

      .p-steps-number {
        min-width: 1rem !important;
        height: 1rem !important;
        padding: 2px !important;
        background: $black-200 !important;
        color: $white !important;
        font-size: $size-8 !important;
      }

      .p-steps-title {
        margin-top: 0 !important;
        margin-left: $size-4;
        font-family: 'AvenirNextM';
        font-weight: normal !important;
      }
    }
  }
  .lead-step-validation.p-highlight,
  .lead-step-validation {
    &:before {
      background-color: #dc3545 !important;
      opacity: 0.8;
    }

    & .p-menuitem-link {
      .p-steps-number {
        background-color: #dc3545 !important;
      }
    }
  }
}

.side-nav {
  .side-nav-inner {
    overflow-y: auto;
    width: auto;
    width: 100%;
    padding: 0 18px 0px 18px !important;
    height: calc(100% - 98px);

    @media screen and (max-width: 1300px) {
      height: calc(100% - 138px) !important;
    }
    .current-nav {
      .bi-envelope::before {
        color: #517dda;
      }
    }
    .bi-envelope::before {
      font-size: 13px;
      color: #7f8f9f;
    }
    .bi-envelope {
      margin-left: 1px;
      margin-top: -1px;
    }
  }
  margin-left: -12px;
  // padding: 0 24px 0px 32px !important;

  &.shadow-middle {
    border-radius: 0 12px 0 0 !important;
  }

  a {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #7f8f9f;
    text-decoration: none !important;
    display: flex;
    align-items: center;

    &.current-nav {
      background: #eff4ff;
      border-radius: 8px;
      color: $primary-main;

      & i::before {
        color: $primary-main;
      }
    }

    & i {
      padding-right: 6px;
      font-size: $size-16;
    }
  }
  .fa-share-square {
    font-size: 13px;
    margin-left: 2px;
    -webkit-text-stroke: 0.04px #ffffff !important;
  }
}

.height-full {
  height: 100vh;
}

.case-progress {
  align-items: center;
  background-color: $black-100;
  border-radius: 8px;
  padding: 2px;

  .progress-bar {
    // text-indent: -9999px; commented to show case_stage_name and number_of_days taken for that stage in Overview menu of Case moduele
    border-radius: $size-8;
    height: 12px;
    background-color: $turquoise;
  }

  .success {
    background-color: $success;
  }
}

.navbar-nav {
  .active {
    .nav-link {
      color: $primary-main;
      font-family: 'AvenirNextD';
    }
  }

  .nav-link {
    font-weight: 500;
    font-size: 14px;
    //line-height: 20px;
    letter-spacing: 0.0025em;
    color: $black-600;
    font-family: 'AvenirNextR';
  }
}

.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 991px) {
  .ml-sm-10 {
    margin-left: 10px;
  }
  .mt-sm-20 {
    margin-top: 20px;
  }
  .mt-sm-10 {
    margin-top: 10px !important;
  }
  .ml-sm-0 {
    margin-left: 0px !important;
  }
  .input-wrap-template {
    display: block !important;
  }
  .p-datatable .p-datatable-tbody > tr {
    border-bottom: 1px solid #cdd2e0 !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 12px 1rem !important;
  }
  .trello-height {
    height: calc(100vh - 212px) !important;
  }
  .add-lead-buttons {
    justify-content: space-between !important;
    width: 100% !important;
  }
  .template-or {
    display: none;
  }
  .template-btns {
    width: 100%;
    margin: 4px 0px !important;
    .p-dropdown {
      margin: 0px !important;
    }
  }
  .templates-wrap {
    .p-dropdown {
      margin: 0px !important;
    }
  }
  .notes-title {
    display: flex;
  }
  .notes-status {
    display: flex;
  }
  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .team-mgmt,
  .team-mgmt-name,
  .team-mgmt-action {
    width: 100% !important;
    display: flex !important;
  }
  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    text-align: left;
    display: inline-flex;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    //font-weight: bold;
  }

  .text-hint {
    margin-left: 10px;
    align-items: center;
    display: flex;
  }
  .related-contacts .p-column-title {
    min-width: 45% !important;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
  .case-tooltip {
    width: 100%;
  }
  .groups-redirection {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .send-review-btn,
  .close-case-btn,
  .delete-case-btn {
    line-height: 1.2 !important;
  }
}

.file-manager-uploader {
  min-height: 150px;
  background-color: $white;
  border: 1px solid #e9e9e9;

  i {
    font-size: $size-24 !important;

    &:before {
      color: $black-200;
    }
  }

  p {
    color: $black-400;
  }
}

.relations-dropdown {
  background: white;
  border-radius: 4px;
  z-index: 1000;
  position: absolute;
  left: 10px;
  top: 85px;
  padding: 10px;
  //width: calc(100% - 50px);
  width: 500px;
  padding: 10px;
  padding-bottom: 70px;
}

.add-contact-dialog {
  height: 800px !important;
  //width: 600px !important;
  border-radius: $size-16 !important;

  .p-dialog-header,
  .p-dialog-content {
    background-color: $background !important;
  }
}

.icon-picker-wrap {
  .rfipbtn--bluegrey {
    width: 100% !important;
  }
}

.file-manager-grid-container {
  @include rounded-8;
  box-shadow: $shadow-sm;
  border-radius: $size-12 !important;
  //padding: 5px;
  height: 200px;
  //width: 216px;
  background-color: $primary-bg;

  .file-manager-img {
    height: 75%;
    width: 100%;

    img {
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 12px 12px 0 0;
    }
  }

  i {
    font-size: $size-12;
    color: $primary-dark;
  }

  p {
    color: $primary-dark;
    font-size: $size-14;

    //font-weight: 100 !important;
    & medium {
      white-space: pre-wrap;
      font-size: $size-12;
    }
  }

  .files-icon {
    i {
      padding-top: 5px;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.pointer {
  @extend .cursor-pointer;
}

.alert-star {
  color: rgb(220, 53, 69);
}

.text-capitalize {
  text-transform: capitalize;
}

//calendar module related
.badge-events {
  background-color: #f5f5fa;

  padding: 10px 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  // text-transform: capitalize;
  // border-left: 3px solid #adaeb2;

  &:hover {
    background-color: #e9ecef;
  }

  .title {
    font-family: 'AvenirNextD';
    // color: #333;
    @extend .text-overflow-ellipsis;
    display: flex;
    // overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap !important;
    display: inline !important;
    overflow: hidden !important;
  }

  .time {
    // color: #666;
    font-size: 90%;
    @extend .text-overflow-ellipsis;
  }

  .badge-event-icon {
    // background-color: #c6b500;
    height: fit-content;
    padding: 3px;
    line-height: 0;
    border-radius: 3px;
    i {
      font-size: 15px;
      //line-height: 1.6;
      &:before {
        color: $white !important; //todo
      }
    }
  }

  .badge-event-details {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: grid;
  }
}

.firm-user-filter {
  position: relative;

  .multiselect-custom {
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
    text-indent: -9999px;
    border: 0 !important;

    .p-multiselect-trigger-icon {
      display: none;
    }

    &.p-focus {
      box-shadow: none !important;
    }
  }

  .p-avatar {
    .p-avatar-text {
      font-size: 0.8rem;
    }
  }
}

.firm-user-select {
  box-shadow: 0 0 4px $black-600;
  background-color: $primary-dark !important;
  color: $white;
}

.md-align-center {
  @media (min-width: 960px) {
    text-align: center !important;
  }
}

.Calendar-wrap {
  background-color: #fff;
  padding: 20px;
  border-radius: $size-16;

  .fc-toolbar {
    text-transform: uppercase;

    .fc-button-group {
      .fc-button {
        &.fc-prev-button {
          background: #f0f2f5;
          border-color: #f0f2f5;
          color: $black-300;
          margin-right: 0.5rem;
          border-radius: 8px !important;
          width: 32px;
          height: 32px;
          padding: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.fc-next-button {
          @extend .fc-prev-button;
        }
      }

      .fc-button-primary {
        background: $white !important;
        border: 1px solid $black-100 !important;
        box-sizing: border-box !important;
        box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
        border-radius: $size-12 !important;
        color: $black-400 !important;
        margin-right: 0.7rem;
        font-weight: 500 !important;
        text-transform: capitalize;
        font-size: $size-14 !important;

        &:last-child {
          margin-right: 0;
        }
      }

      .fc-button-active {
        background: $primary-main !important;
        color: $white !important;
        border-color: $primary-main !important;
      }
    }

    .fc-toolbar-title {
      font-size: 24px;
      font-family: 'AvenirNextD';
      color: $black-800;
    }
  }

  .fc.fc-theme-standard .fc-view-harness th {
    //background: #fafbfe;
    background: #ffff;
    border: 0;
    color: #000000;
    height: 15px;
    vertical-align: top;
    text-align: right;
  }

  .fc .fc-col-header-cell-cushion {
    text-transform: uppercase;
    text-decoration: none;
    font-size: $size-12;
    padding: $size-8;
    font-family: 'AvenirNextM';
    color: $black-400;
  }

  .fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: #dee2e6;
    border: 0;

    .fc-scrollgrid {
      thead {
        position: absolute;
      }
    }
  }

  .fc-daygrid-day-number {
    text-decoration: none !important;
  }

  .fc-daygrid-day-top {
    justify-content: flex-end;
    font-size: 16px;
    font-family: 'AvenirNextM';
  }

  .fc-v-event {
    background: none !important;
    border: 0;
    box-shadow: none;
  }
  .fc-timegrid-event {
    .fc-event-main {
      padding: 0 !important;
    }
  }

  .fc-popover {
    z-index: 999 !important;
    border-radius: 16px !important;
    min-width: 25rem !important;
    .fc-popover-header {
      border: 0 !important;
      background: $white;
      border-radius: 16px 16px 0 0;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-family: 'AvenirNextD' !important;
      color: #677a8e !important;

      .fc-popover-close {
        display: none !important;
      }
    }
    .fc-popover-body {
      border: 0 !important;
      border-radius: 0 0 16px 16px;
    }
  }
}

.fc-scrollgrid-liquid {
  thead {
    .fc-scrollgrid-section-header {
      position: absolute;
    }
  }
}

// ------------------ switch toggle ------------------ //@at-root
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black-100;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: $black-300;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;
    }

    &.round:before {
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: $success-bg;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $success-bg;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: $success;
  }
}

// ------------------ // switch toggle ------------------ //
.icon-picker-wrap {
  .rfip {
    margin: 0;
    width: 100%;

    .rfipbtn {
      min-height: 38px;
      box-shadow: $shadow-sm;
      border-radius: $size-12 !important;
      border: none;
    }

    .rfipbtn__button {
      background-color: $white;
      border-radius: 0 12px 12px 0;
      border-left: none;
      color: $black-400;

      &:hover {
        background-color: $black-100;
      }

      &--open {
        background-color: $black-100;
      }
    }
  }

  .rfipdropdown--bluegrey {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}

.p-avatar {
  text-transform: capitalize !important;
  font-size: $size-14 !important;
}

.task-card {
  .sub-title {
    color: $black-600;
  }

  .p-medium {
    font-size: 15px;
  }

  .status {
    &.high {
      color: $error;
    }

    &.medium {
      color: $yellow;
    }

    &.low {
      color: $success;
    }
  }
}

.personal-info-wrap {
  background-color: $primary-bg;
}

.timer-wrap {
  @extend .shadow-small;
  border-radius: $size-8 !important;
  padding: 0.2rem;
  height: 32px;

  .icon {
    font-size: 1.2rem;

    &.icon-timeStop-fill {
      &::before {
        color: $icon-red;
      }
    }
  }

  .p-avatar {
    background-color: $icon-red;
    color: $white;
    width: 1rem;
    height: 1rem;

    .p-avatar-text {
      font-size: 8px !important;
    }
  }
}

.p-inputwrapper-focus {
  // box-shadow: $shadow-sm !important;
  border-radius: 12px;
}

.task-wrap,
.leads-draggable {
  /*.sc-jRQBWg {
    display: flex;
    overflow: auto !important;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }*/
  .p-avatar-group .p-avatar-circle {
    margin-left: -0.7rem !important;
  }
  .p-avatar-group .p-avatar-circle:hover {
    z-index: 1;
  }
  .smooth-dnd-container {
    width: 100%;
    min-height: 350px;

    .container {
      box-shadow: $shadow-sm;
      //width: 95% !important;
      width: 336px !important;
      margin: 4px !important;
      border-radius: 12px;
      margin-bottom: 16px !important;
    }
  }

  .task-wrp-bg {
    background-color: $background;
    height: 100% !important;
    overflow-x: auto !important;
  }

  .eaLrpa {
    background-color: $background;
    margin: 0 !important;
    padding: 0;
    padding-right: 24px;
  }

  .react-trello-lane {
    //background-color: $background !important;
    // background-color: $black-100 !important;
    padding: 10px;
    margin-right: 10px !important;
    border-radius: 16px;
    min-width: 364px;
    height: 75vh;
  }

  .smooth-dnd-draggable-wrapper {
    .container {
      box-shadow: $shadow-sm;
      //width: 95% !important;
      width: 336px !important;
      margin: 4px !important;
      border-radius: 12px;
      margin-bottom: 16px !important;
    }

    header {
      span {
        font-size: $size-16 !important;
        font-weight: 600 !important;
        color: $black-600 !important;
      }
    }
  }

  // .jFanTw {
  //   max-height: 100% !important;
  // }

  .react-trello-lane div:nth-child(2) {
    max-height: 100% !important;
  }

  .hXiTVc,
  .ffjKMh {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .btn-add-task {
    background-color: $white;
  }

  .task-heading {
    font-family: 'AvenirNextD';
    color: $black-600;
  }

  .priority-dropdown {
    width: 65px !important;
    height: 32px;
    border-radius: $size-8 !important;

    .pi-chevron-down {
      &::before {
        font-size: $size-12;
      }
    }
  }

  // .p-inputwrapper-focus {
  //   box-shadow: $shadow-sm !important;
  // }

  .sub-tasks {
    border-radius: 8px;
  }
}

.task-wrap {
  .columns .column {
    margin-right: 10px;
    padding: 10px;
    border-radius: 16px;
    background-color: rgb(240, 242, 245);
  }
  .columns .column:nth-child(2n) {
    background-color: rgb(239, 244, 255);
  }

  .task-list {
    margin-top: 10px;
  }
  .task-list .item .card-layout {
    box-shadow: 0 1px 3px rgba(103, 122, 142, 0.24);
    border-radius: 12px;
  }
  .task-sub-head {
    color: $black-500;
    font-weight: 600;
  }
}

.task-avatar {
  width: 1.5rem !important;
  height: 1.5rem !important;
  font-size: $size-12 !important;
}

.p-datepicker {
  border-radius: $size-16 !important;
  height: auto !important;

  table {
    font-size: $size-14 !important;

    thead {
      tr {
        th {
          text-align: center !important;

          span {
            font-family: 'AvenirNextM';
          }
        }
      }
    }

    td {
      padding: 0.1rem;

      span {
        width: 1.5rem !important;
        height: 1.5rem !important;

        &:hover {
          border-radius: $size-8;
        }
      }
    }

    .p-datepicker-today {
      span {
        border-radius: $size-8;
        background: $primary-main !important;
        color: $white !important;
      }
    }
  }

  .p-datepicker-title {
    .p-datepicker-month,
    .p-datepicker-year {
      border: 0 !important;
    }
  }

  .p-timepicker {
    span {
      font-size: $size-12;
    }
  }
}

/* .p-datepicker {
  z-index: 9999 !important;
  border-radius: 10px !important;
  .p-datepicker-calendar-container {
    padding: 5px;

    .p-datepicker table td {
      padding: 0.1rem !important;
    }
  }

  .p-datepicker-buttonbar {
    display: none !important;
  }

  .p-datepicker-header {
    .p-datepicker-prev,
    .p-datepicker-next {
      display: none !important;
    }
    .p-datepicker-title {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .p-datepicker-month,
      .p-datepicker-year {
        border: 0;
      }
    }
  }
} */

.task-clickmore {
  span {
    width: 100%;
    text-align: end;
  }

  .p-card-body {
    padding: 0 !important;
  }

  .popup-dtls {
    top: 8px;
  }
}

.sign-up-spinner {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  z-index: 10000 !important;
  right: 5px !important;
  top: 5px !important;
}

.password-conditions {
  ul {
    padding-left: 25px;
    font-size: $size-12;
    color: $black-500;
  }
}

.webviewer {
  height: 100vh;
}

.dialog-minimize {
  height: 0px;
  box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;

  .p-dialog-content {
    visibility: hidden;
  }

  .p-dialog-header {
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
  }
}

.remove-default-password-icon {
  //for ms edge browser
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}

.p-dialog .p-dialog-header-icons {
  align-items: flex-start !important;

  :hover {
    background: none !important;
  }

  :focus {
    box-shadow: none !important;
  }
}

.p-overlaypanel {
  box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;

  .p-overlaypanel-content {
    cursor: pointer;
  }
}
.F-size11 {
  font-size: 11px !important;
}
.F-size14,
.expense-popup {
  font-size: $size-14 !important;
}
.F-size13 {
  font-size: 13px !important;
}
.F-size12 {
  font-size: $size-12 !important;
  font-family: 'AvenirNextR';

  .p-tag-value {
    @extend .F-size12;
  }
}

.black-500 {
  color: $black-500;
}
.black-400 {
  color: $black-400;
}
.black-600 {
  color: $black-600 !important;
}

.billing {
  color: $turquoise;
}

.nonbilling {
  color: $violet;
}

.line_height {
  line-height: 1.1;
}
.line_height2 {
  line-height: 2;
}
.line_height3 {
  line-height: 3;
}

.line_height0 {
  line-height: 0;
}

.F-size16 {
  font-size: $size-16 !important;
}

.list-view,
.grid-view {
  border: 1px solid $primary-main !important;
  background-color: transparent !important;
  padding: 0.5rem !important;
  border-radius: 10px !important;

  i {
    color: $primary-dark !important;
    font-size: $size-16;

    &:before {
      color: $primary-dark !important;
    }
  }
}

.create-document-button {
  border: 1px solid $primary-main !important;
  padding: 0.5rem !important;
  border-radius: 10px !important;
  width: 34px;
  height: 34px;
  .bi-magic {
    margin-left: 1px;
    margin-top: -2px !important;
    display: block;
  }
  i {
    &::before {
      color: $primary-dark !important;
    }
  }
}

.gear-icon {
  line-height: 0;
  border-radius: $size-12;
}

.cal-gear {
  border: 1px solid $black-400 !important;
}

.height25 {
  height: 250px;
}

.component-level-spinner {
  width: 50px;
  height: 50px;
}

.billing-wrap {
  .p-datatable-wrapper {
    overflow-x: auto;
  }
  .p-tabview-nav-next {
    display: none;
  }

  .p-tabview-panels {
    padding: 0 !important;
  }

  .p-tabview-nav-content {
    width: auto;
    font-size: $size-14;
    padding: 3px 0;

    .p-tabview-nav {
      border: 0;
      width: fit-content;
      background: none;

      li:first-child a {
        border-radius: 12px 0 0 12px;
        border: none;
      }

      li:last-child a {
        //border-radius: 0 20px 20px 0 !important;
        border: none;
      }

      .p-highlight .p-tabview-nav-link {
        background-color: $primary-bg !important;
        font-family: 'AvenirNextM';
        color: $primary-main;
      }

      .p-tabview-nav-link:focus {
        box-shadow: none !important;
      }

      .p-tabview-nav-link {
        font-weight: 400 !important;
        padding: 10px 12px !important;
        border: 0;
        border-radius: 0;
      }
    }
  }

  .p-tabview-panels {
    background: none !important;
  }
}

.filter-calender {
  button {
    background: $white;
    border: 0;
    border-radius: 0 12px 12px 0 !important;
    max-width: 100%;
  }

  .p-button:hover {
    background: $white !important;
  }

  .p-inputtext:enabled:hover {
    border-color: transparent !important;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border-color: transparent !important;
  }

  .p-inputtext {
    border-radius: 12px 0 0 12px !important;
  }
}

.file-manager-sidebar {
  .p-sidebar-header {
    display: none;
  }

  .icon-back {
    border: 1px solid $primary-main;
    border-radius: $size-8;
  }
}

.w-45 {
  width: 45%;
}

.pi-times,
.pi-check {
  color: $primary-dark !important;
}

.new-task-popup {
  input {
    width: 100%;
    height: fit-content;
  }
}

.view-message-editor .message-reply .p-editor-toolbar {
  display: none !important;
}

.view-message-editor .message-reply .p-editor-content {
  border: 0 !important;
}

.field-icon button {
  background-color: inherit;
  border: 0;

  &:hover {
    background-color: inherit !important;
  }
}

.p-multiselect {
  border: 1px solid #f0f2f5 !important;
  line-height: 1.7;
}

.plus-btn {
  background-color: $black-100;
  cursor: pointer;
  border-radius: $size-14;
  width: 100px;
}

.top-button {
  height: 32px;
  border-radius: $size-8 !important;
}

.nav-down-arrow {
  font-size: 10px !important;
  margin-left: 5px !important;
}

.top-nav-avatar {
  a {
    padding: 0 8px;

    &::after {
      display: none;
    }
  }

  & .p-avatar {
    width: 24px;
    height: 24px;
    font-size: $size-12 !important;
    border: 1px solid $primary-dark;
  }
}

#popup_menu,
#popup_menu .p-submenu-list {
  min-width: 150px !important;
  padding: 5px;
  box-shadow: $shadow-sm;
  border-radius: $size-12 !important;

  ul {
    & .p-submenu-header {
      display: none;
    }

    & .p-menuitem-link {
      font-size: $size-14;
      padding: 8px 10px;
      background-color: $background;
      margin: 5px 0;
      border-radius: 10px;
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.pr-details {
  .pr-heading {
    background-color: $white;
  }
}

.PSPDFKit-Container {
  height: calc(100% - 55px) !important;
}
.p-badge {
  position: absolute;
  font-size: 8px !important;
  width: auto;
  min-width: 15px !important;
  padding: 2px 3px !important;
  height: 15px !important;
  margin-left: 10px;
  margin-bottom: 15px;
  line-height: normal !important;
  background: $primary-dark !important;
}
.y-badge {
  position: absolute;
  font-size: 8px !important;
  width: auto;
  min-width: 15px !important;
  padding: 2px 3px !important;
  height: 15px !important;
  margin-left: 32px;
  margin-bottom: 15px;
  line-height: normal !important;
  background: #ff583e !important;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  color: #fff;
}

.badge-text {
  background: #edfaef !important;
  padding: 5px !important;
  line-height: normal !important;
  border-radius: 20%;
  font-family: 'AvenirNextM';
  color: #4ba457 !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.overlaypanel-timer {
  .p-overlaypanel-content {
    padding: 5px !important;
  }
  &:before,
  &:after {
    display: none !important;
  }
}

.orders-subtable {
  padding: 1rem;
}

.case-details-wrap {
  .p-dropdown-label,
  .p-multiselect-label {
    width: 178px !important;
  }

  .input-search {
    width: 320px !important;
    //width: 250px !important;
    color: $black-400 !important;
    border-radius: $size-8 !important;
    border: 1px solid #f0f2f5 !important;
  }

  .adv-filter {
    .p-button-label {
      font-size: $size-14;
      //font-weight: 600;
    }
  }

  .p-datatable {
    .p-datatable-thead > tr > th {
      background: none;

      &:hover {
        background: none !important;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .case-details-link {
      font-size: $size-14;
      line-height: 20px;
      // font-weight: 600;
      font-family: 'AvenirNextD';
    }
  }
}
.p-paginator .p-dropdown {
  border: 1px solid $primary-main !important;
  box-shadow: none;

  .p-dropdown-label {
    color: $primary-main !important;
  }
}
.p-paginator .p-dropdown-trigger-icon {
  color: $primary-main !important;
}
.p-paginator {
  background-color: inherit !important;
  justify-content: left !important;
  margin-top: 2rem;
  border: 0 !important;

  // .p-paginator-next, .p-paginator-prev{
  //   background-color: $white !important;
  //   border-color: $white !important;
  //   height: 37.5px !important;
  //   min-width: 37.5px !important;
  //   opacity: 0.9 !important;
  //   box-shadow: $shadow-md;
  //   border-radius: $size-8 !important;
  // }

  .p-paginator-pages {
    font-size: $size-12;

    .p-highlight {
      background-color: transparent !important;
      border: 1px solid $primary-main !important;
    }

    .p-paginator-page {
      height: 32px !important;
      min-width: 32px !important;
    }
  }

  .p-inputtext {
    width: auto !important;
  }

  .p-dropdown {
    margin-right: 10px;
  }
}

.case-title {
  font-size: $size-24;
  // font-weight: 600;
  line-height: 32px;
}

.tag-case {
  background-color: $black-100 !important;
  border-radius: 8px !important;
  padding: 4px 8px !important;
  cursor: pointer;
}

.dashboard-top-card .p-card-content {
  padding: 0 !important;
}
.client-height-row {
  height: calc(100% - 40px);
}
.client-avatar {
  .p-avatar {
    height: 40px !important;
    width: 40px !important;
  }

  a {
    font-family: 'AvenirNextM';
    color: $black-900 !important;
  }
}

.status-point {
  width: 12px;
  height: 12px;
  background-color: $turquoise;
  border-radius: $size-4;
}

.p-multiselect-items-wrapper {
  .p-multiselect-items {
    & li {
      font-size: $size-14 !important;
      border-radius: 8px !important;
      background-color: $background !important;
      margin-bottom: 5px !important;
      max-width: 560px;
      white-space: break-spaces;
    }

    .p-highlight {
      background-color: $primary-bg !important;
    }
  }
}

.casefile-wrap,
.billing-wrap {
  .p-datatable-wrapper {
    .p-datatable-thead {
      & th {
        background: transparent !important;
      }
    }
  }
}

.d-tag {
  background-color: $black-100 !important;
  border-radius: 8px !important;

  .p-tag-value {
    color: $black-900 !important;
    font-weight: 500 !important;
  }
}

.s-tag {
  background-color: $success-bg !important;
  border-radius: 8px !important;

  .p-tag-value {
    color: $success !important;
    font-weight: 500 !important;
  }
}

.y-tag {
  background-color: #fff9d5 !important;
  border-radius: 8px !important;

  .p-tag-value {
    color: #5a5749 !important;
    font-weight: 500 !important;
  }
}

.da-tag {
  background-color: $sec-error !important;
  border-radius: 8px !important;
  color: $error !important;

  .p-tag-value {
    color: $error !important;
    font-weight: 500 !important;
  }
}

.buget-value {
  color: $success;
}

.input-swich {
  width: 40px !important;
  height: 24px !important;
  display: flex !important;

  .p-inputswitch-slider {
    &::before {
      width: 1rem !important;
      height: 1rem !important;
      margin-top: -0.5rem !important;
    }
  }
}

// .p-inputswitch{
//   .p-inputswitch-checked{
//     .p-inputswitch-slider{
//       background:$success-bg !important;
//     }
//   }
// }

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $success-bg !important;

  &::before {
    background: $success !important;
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.04rem) !important;
}

.input-search {
  width: 320px !important;
  //width: 250px !important;
  color: $black-400 !important;
  border-radius: $size-8 !important;
  padding-left: 1.6rem !important;
}

.p-dialog {
  height: auto !important;

  .p-dialog-header {
    border-top-right-radius: 16px !important;
    border-top-left-radius: 16px !important;

    .p-dialog-title {
      font-family: 'AvenirNextD';
      // font-size: $size-16 !important;
    }
  }

  .p-dialog-content {
    border-bottom-right-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
  }
}

//.billing-filter,
//.expense-filter,
.file-manager-wrap .FM-filter,
.task-filter,
.filter-calender,
.automation-filter,
.note-filter {
  .p-inputtext {
    width: 178px !important;
    height: 40px !important;
  }
  .w-110 {
    width: 110px !important;
  }
}

@media screen and (min-width: 991px) and (max-width: 1180px) {
  .add-contact-name {
    width: 120px !important;
  }
  .automation-filter {
    .error-log-filters {
      width: 25% !important;
      margin-left: 8px !important;
    }
  }
}
.automation-filter,
.report-filters {
  .report-filter-container {
    width: calc(100% - 9px) !important;
  }
  .error-log-filters {
    width: 17.2%;
  }
  .report-filter-wrapper {
    width: 15%;
  }
  .filter-label {
    width: 5%;
    text-wrap: nowrap;
  }
  .p-multiselect-label {
    width: auto !important;
    height: 40px !important;
  }
  .report-calendar {
    .p-inputtext {
      width: 80% !important;
    }
  }
  .p-inputtext {
    width: 120px !important;
    height: 40px !important;
  }
  .p-multiselect-token {
    width: 100%;
  }
  .p-multiselect-token-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .p-multiselect-label {
    padding: 0.5rem 0.5rem !important;
  }
  .p-multiselect-token {
    margin-top: -5px;
    width: 90% !important;
  }
  .p-multiselect-label-container {
    width: 120px;
  }
  .p-dialog-content {
    padding-bottom: 0px !important;
  }
}

.billing-filter,
.expense-filter {
  .p-inputtext {
    width: 120px !important;
    height: 40px !important;
  }

  .p-multiselect-label {
    padding: 0.5rem 0.5rem !important;
  }
}

.task-wrap {
  .task-container {
    background-color: $background;
  }

  .task-lane-header-options,
  .task-number-header,
  .btn-add-task {
    height: 35px;
    width: 35px;
    border-radius: 8px !important;
  }

  .task-number-header {
    background-color: $white;
  }

  hr {
    background: $black-200;
  }

  .add-task-btn {
    background-color: $black-100;
    width: 38px;
    height: 296px;
    margin-left: 35px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.file-manager-wrap {
  .outline {
    background-color: transparent !important;
  }
}

.bc-overlaypanel {
  right: 0;
  width: 800px !important;
  height: 400px;
  margin-right: 50px;
  top: 300px;
  border-radius: 16px !important;

  &::after {
    display: none !important;
  }

  &::before {
    display: none !important;
  }

  .p-overlaypanel-close {
    display: none;
  }
}

.FM-popup,
.p-confirm-dialog,
.bottom-radius,
.managefirm-modal,
.delete-modal,
.common-popup-style {
  .p-dialog-content {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.bottom-radius,
.p-confirm-dialog,
.managefirm-modal,
.delete-modal,
.common-popup-style {
  .p-dialog-footer {
    border-bottom-right-radius: $size-16 !important;
    border-bottom-left-radius: $size-16 !important;
  }
}

.sharefile {
  .p-dialog-content {
    border-bottom-right-radius: $size-16 !important;
    border-bottom-left-radius: $size-16 !important;
  }
}

.addnewcontact {
  border-radius: $size-16 !important;
}

.table-icons {
  .icon-save-icon {
    font-size: 14px;
  }
  i {
    cursor: pointer;
    font-size: $size-16;
  }

  .icon-more {
    transform: rotate(90deg);
  }
  .icon-done {
    font-size: 19px !important;
  }
}

.contact-wrap,
.team-management-wrap,
.lead-filter {
  .p-dropdown-label {
    width: 178px !important;
  }

  .p-datatable-wrapper {
    background-color: $white;
    padding: 16px;
    box-shadow: $shadow-md;
    @extend .b-radius16;
  }
}

.logo_dashboard {
  max-width: '40px';
  margin-left: -35;
}

.version {
  font-size: 14px;
  font-weight: 200;
}

.add-task-from-lane-header {
  height: 35px;
  width: 35px;
}

.p-sidebar-close {
  &:hover {
    background: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.tag-wrp {
  a {
    background-color: $black-100;
    padding: 5px;
    font-size: 12px;
    border-radius: 8px;
    text-decoration: none;
    color: $black-700;
  }
}

.table-title {
  font-family: 'AvenirNextD';
  color: $primary-dark;
  text-transform: capitalize;
}

small {
  font-size: 14px !important;
}

.view-invoice-wrap {
  .invoice-table {
    tbody {
      tr {
        td {
          background: none !important;
          box-shadow: none !important;
          border-bottom-width: 0 !important;

          &:nth-of-type(even) {
            font-family: 'AvenirNextM';
          }
        }
      }
    }
  }
}

.time-entries {
  .table {
    thead {
      tr {
        th {
          font-size: $size-12 !important;
          font-weight: 100;
        }
      }
    }

    tbody {
      border-top: 1px solid #dee2e6 !important;

      tr {
        &:nth-of-type(odd) {
          background-color: $background;
        }

        td {
          border: 0 !important;
          font-size: $size-14;
          background-color: none !important;
          box-shadow: none !important;
        }
      }
    }
  }

  tfoot {
    border-top: 0px !important;
    font-size: $size-14;
  }
}

.invoice-popup {
  .p-dialog-header,
  .p-dialog-content {
    background-color: $background !important;
    border-radius: 0 !important;
  }
}

.select-status {
  width: 100px;
  height: 35px;
  align-items: center;
  justify-content: center;
}

.login-dropdown {
  padding: 8px;
  top: 90%;
  width: 216px;

  @media screen and (max-width: 991px) {
    right: 0px !important;
  }

  .dropdown-item {
    font-size: 14px;
    padding: 5px 10px;
    background-color: $background;
    margin: 5px 0;
    border-radius: 10px;
  }
}

.msg-filters {
  .p-multiselect-label,
  .msg-filters,
  .p-inputtext {
    width: 140px !important;
    height: 40px !important;
  }

  .by-date {
    .p-inputtext {
      width: 178px !important;
    }
  }
}

.b-radius16 {
  border-radius: $size-16 !important;
}

.dialog-close-red-round {
  height: 15px;
  width: 15px;
  background-color: red;
}

.dialog-minimise-yellow-round {
  height: 15px;
  width: 15px;
  background-color: yellow;
}
.billing-case {
  .curve-tab {
    .p-tabview-nav-container {
      margin-bottom: 5px;
    }
  }
}
.curve-tab {
  li:nth-last-child(2) {
    .p-tabview-nav-link {
      border-top-right-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
    }
  }
}

.p-datatable {
  .p-datatable-thead > tr > th {
    background: none !important;

    //padding: 10px !important;
    &:hover {
      background: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.overview-wrap,
.waiting-payment {
  small,
  .text-muted {
    font-size: $size-12 !important;
    color: $black-400 !important;
  }
}

.p-checkbox {
  width: 14px !important;
  height: 14px !important;
  vertical-align: middle !important;

  //display: block !important;
  .p-checkbox-box {
    width: 14px !important;
    height: 14px !important;
    border: 1px solid #2196f3 !important;

    :focus {
      box-shadow: none !important;
    }

    .p-checkbox-icon {
      font-size: 9px !important;
      color: #fff !important;
    }
  }

  .p-focus {
    box-shadow: none !important;
  }
  .checkbox-disabled {
    border: 2px solid #ced4da !important;
  }
}

.team-member {
  span {
    font-size: $size-12 !important;
  }

  .p-datatable-tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }

  .p-checkbox {
    vertical-align: baseline;
  }
}
.conversation-container {
  padding-left: 50px;
  padding-right: 50px;
}
.message-wrap {
  .p-avatar {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    font-size: $size-12 !important;
  }

  .tag-case {
    width: 42px;
    height: 24px;
  }

  .upload-document {
    p,
    i {
      color: $primary-dark;
    }
  }

  .input-search {
    width: 320px !important;
    color: #7f8f9f !important;
    border-radius: 8px !important;
    border: 1px solid #f0f2f5 !important;
  }
}
.intuit-payment-popup {
  .p-dialog-header {
    background-color: $black-100 !important;
    padding: 10px !important;
    height: 32px;
  }
}
.create-msg-popup {
  width: 40vw;

  .p-dialog-content {
    background-color: $background !important;

    .attachmnet-input {
      background-color: $white;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }
  }

  .p-dialog-header {
    background-color: $black-100 !important;
    padding: 10px !important;
    height: 32px;
  }

  .p-editor-container {
    box-shadow: $shadow-sm;
    border-radius: 12px;

    .p-editor-toolbar {
      border: 0 !important;
      border-radius: 12px 12px 0 0 !important;
      font-family: 'AvenirNext' !important;
    }

    .p-editor-content {
      border: 0 !important;

      .ql-editor {
        border-radius: 0 0 12px 12px !important;
      }
    }
  }
}

.new-task-popup,
.run-report-popup {
  width: 40vw;

  .p-dialog-header {
    background-color: $black-100 !important;
    padding: 10px !important;
    height: 32px;

    .p-dialog-header-icons {
      display: none !important;
    }
  }

  .p-dialog-content {
    background-color: $background !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    font-size: $size-14 !important;
    padding-top: 1.5rem !important;
  }

  .p-dialog-footer {
    background-color: $background !important;
    border-bottom-right-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
  }

  .p-inputtext,
  .p-multiselect {
    height: 40px !important;
    font-size: $size-14 !important;

    &:focus {
      box-shadow: $shadow-sm;
    }
  }

  .p-inputtextarea {
    height: auto !important;
  }
}

.new-task-popup .add-tag-chips-input .p-inputtext {
  height: auto !important;
}

.create-reminder-popup {
  width: 40vw;

  .p-dialog-header,
  .p-dialog-content {
    background-color: $background !important;
  }
}

.icon-minimize {
  padding-left: 5px;

  &::before {
    color: $lemon;
  }
}

.msg-reply-wrap {
  .avatar-wrp {
    width: 60px;
    display: flex;
    align-items: center;

    .p-avatar {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .ql-editor {
    padding: 5px 0 !important;
  }

  .p-chip {
    background: none !important;
    color: $primary-dark;

    i {
      color: $primary-dark;
    }

    .icon-calendar {
      &::before {
        color: $primary-dark;
      }
    }
  }

  .attachments {
    padding-left: 65px;
  }

  .view-message-editor {
    font-size: $size-14 !important;
    font-family: 'AvenirNext' !important;
  }
}

.case-delete-popup {
  .p-dialog-content {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .p-dialog-footer {
    border-bottom-right-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
  }
}

.case-sidebar {
  .team-member {
    .p-datatable-tbody {
      tr {
        td {
          padding: 10px !important;
        }
      }
    }

    .p-datatable-thead {
      tr {
        th {
          padding: 10px !important;
        }
      }
    }
  }
  .p-inline-message.p-inline-message-warn {
    background: #ffefc1;
    border-radius: 12px;
    .p-inline-message-text {
      font-size: 14px;
    }
  }
}

.tbl-input {
  width: 150px !important;
  height: 32px !important;
  padding: 5px !important;

  .p-dropdown-label {
    //padding: 5px !important;
    line-height: 0.5;
  }
}

.dashboard-wrap {
  .dashboard-dropdown {
    // width: 112px !important;
    width: 132px !important;
    height: 32px !important;
    border-radius: 8px !important;

    .p-dropdown-label {
      padding: 5px !important;
      padding-left: 12px !important;
    }
  }

  .time-manager {
    .p-progressbar {
      height: 16px;
      border-radius: $size-8;
      background-color: $black-100;

      .p-progressbar-value {
        border-radius: $size-8;
        border: 2px solid $black-100;
      }

      .p-progressbar-label {
        font-size: $size-8;
        line-height: 1rem;
      }
    }
  }

  .dashboard-activity {
    .p-tag-value {
      font-size: $size-12;
      font-weight: 500;
    }

    .tag-case {
      padding: 4px 8px !important;
    }
  }

  .dashboard-tablelisting {
    .p-tabview {
      .p-tabview-panels {
        background-color: $background !important;
        padding: 32px 0 !important;
      }

      .p-tabview-nav {
        border: 0 !important;
        width: fit-content;
        background: none;
        padding-bottom: 5px;

        //padding-left: 8px;
        .p-highlight {
          .p-tabview-nav-link {
            background-color: #eff4ff !important;
            font-family: 'AvenirNextM';
            color: $primary-main;

            &:focus {
              box-shadow: none !important;
            }
          }
        }

        .p-tabview-nav-link {
          font-weight: 400 !important;
          padding: 10px 12px !important;
          border: 0;

          &:focus {
            box-shadow: none;
          }
        }

        li:first-child a {
          border-radius: 12px 0 0 12px;
          border: none;
        }
      }

      li:nth-last-child(2) {
        .p-tabview-nav-link {
          border-top-right-radius: 12px !important;
          border-bottom-right-radius: 12px !important;
        }
      }
    }

    .recent-cases-tbl {
      .p-datatable-wrapper {
        .p-datatable-tbody {
          tr {
            td {
              //padding: 10px;

              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .activity-log-wrap {
    .sub-title {
      font-size: $size-14;
      color: $black-600;
    }
  }
}

.m-tag {
  color: #f8955d !important;
  background-color: #fff5f2 !important;
  border-radius: 8px !important;
}

.image-upload > input {
  display: none;
}

.case-contact {
  .p-card {
    background: none !important;
    box-shadow: none;

    .p-card-body {
      padding: 0;
    }

    .p-card-content {
      background-color: $white;
      box-shadow: $shadow-md;
      padding: 1rem;
      border-radius: 1rem;
    }

    .p-card-title {
      @extend .caption-bold;
    }
  }

  .client-btn {
    a {
      text-decoration: none;
      padding: 8px;
      border: 1px solid $primary-main;
      border-radius: $size-8;
      font-size: $size-12;

      i {
        padding: 5px 5px 5px 0px;
      }
    }
  }

  .client-name {
    a {
      text-decoration: none;
      font-family: 'AvenirNextM';
      color: $primary-dark;
    }
  }

  .case-team-member {
    .p-datatable-thead {
      tr {
        th {
          //padding: 6px;
          line-height: normal;
        }
      }
    }
  }

  .p-datatable-tbody {
    tr {
      td {
        //padding: 6px !important;
        vertical-align: middle;
      }
    }
  }

  .relations-box {
    background-color: $background;
    border-radius: $size-8;
  }
}

.task-add-stage {
  width: 300px;
  .p-dialog-content {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    background-color: $background !important;
  }

  .p-dialog-footer {
    border-bottom-right-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
    background-color: $background !important;
    padding: 10px !important;
  }

  .p-dialog-header {
    background-color: $background !important;
    padding: 10px !important;

    .p-dialog-title {
      font-size: $size-14 !important;
    }

    .p-dialog-header-icon {
      .pi-times {
        font-size: $size-14 !important;
      }
    }
  }
}

.activity-log-wrap {
  padding: 1rem;
  border-radius: $size-16;
  box-shadow: $shadow-md;

  .slider-ctrl {
    width: 32px;
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    background-color: $black-100;

    i {
      color: $black-300;
    }
  }
}

.mange-colum-modal {
  width: 50vw !important;

  .outline-dropdown {
    background: none !important;
    border: 1px solid $primary-dark !important;
    box-shadow: none;

    .p-placeholder {
      color: $primary-dark !important;
    }

    .pi-chevron-down {
      color: $primary-dark !important;
    }

    .p-dropdown-label {
      width: auto !important;
    }
  }
}

.priority-tags {
  width: 63px;
  height: 24px;
  align-items: center;
  font-size: 12px;
}

.p-dropdown {
  .p-placeholder {
    color: $black-400 !important;
  }
}

.full-calendar-wrap {
  .p-avatar {
    width: 40px;
    height: 40px;
  }

  .gear-icon {
    i {
      font-size: 20px !important;
    }
  }
}

.eventsetting-slider {
  width: 28rem !important;
  z-index: 100;
}

.team-management-wrap {
  .team-table {
    a {
      //line-height: 2.5;
      padding-left: 5px;
      text-decoration: none;
      color: $black-900;
      font-weight: 500;
      display: inline-block;
    }

    // .d-inline-table{
    //   display: flex;
    //   align-items: center;
    // }

    .p-avatar {
      width: 24px;
      height: 24px;
      border-radius: 8px !important;

      img {
        border-radius: 8px !important;
      }
    }
  }
}

.user-details {
  .image-upload {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      background: #edeef2;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-direction: column;

      i {
        font-size: $size-20;
        transform: rotate(180deg);
      }
    }
  }

  input {
    font-size: $size-14;
  }

  a {
    text-decoration: none;
    color: $black-900;
  }
}

.permissions-table {
  table {
    tbody {
      border-top: none !important;

      tr {
        border: none !important;

        &:nth-of-type(odd) {
          background-color: $background;
        }

        td {
          padding: 10px;
          border: none !important;
        }

        th {
          border: none !important;
        }
      }
    }
  }
  .event-permission-table {
    table-layout: fixed;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: $background;
}

.manage-profle,
.contact-dtl-wrap,
.custom-fields {
  .p-tabview-nav-next {
    display: none;
  }

  .p-tabview-panels {
    padding: 0 !important;
  }

  .p-tabview-nav-content {
    width: auto;
    margin-bottom: 10px;
    font-size: $size-14;
    padding: 3px 0;

    .p-tabview-nav {
      border: 0;
      width: fit-content;
      background: none;

      li:first-child a {
        border-radius: 12px 0 0 12px;
        border: none;
      }

      li:last-child a {
        border: none;
      }

      .p-highlight .p-tabview-nav-link {
        background-color: $primary-bg !important;
        font-family: 'AvenirNextM';
        color: $primary-main;
      }

      .p-tabview-nav-link:focus {
        box-shadow: none !important;
      }

      .p-tabview-nav-link {
        font-weight: 400 !important;
        padding: 10px 12px !important;
        border: 0;
        border-radius: 0;
      }
    }
  }

  .p-tabview-panels {
    background: none !important;
  }
}

.contact-dtl-wrap {
  .contact-delete {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .add-select-con {
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
  }

  .fa-sync {
    font-size: 11px;
  }
}

.managefirm-modal,
.addnew-modal,
.modal-custom-field,
.common-popup-style,
.client-dtls,
.popup-bg,
.p-confirm-dialog {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    background-color: $background !important;
  }

  .p-button {
    @extend .p-button-secondary;
  }
}

.arrow-previous {
  line-height: 1;
}

.modal-custom-field {
  width: 500px;
}

.Lead-wrap {
  background: none !important;
  overflow: auto !important;
  height: 100% !important;

  .create-lead-btn {
    background-color: $black-100;
    width: 38px;
    height: 296px;
    margin-left: 35px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-avatar {
    width: 24px;
    height: 24px;
  }

  .icon-contacts,
  .icon-calendar,
  .icon-workload {
    font-size: 18px;
  }
}

.p-multiselect-items {
  .p-checkbox-box {
    .p-checkbox-icon {
      color: $black-800 !important;
    }
  }
}

.addfield {
  color: $primary-dark !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: 'AvenirNextM';
  cursor: pointer;

  i {
    color: $primary-dark !important;

    &:before {
      color: $primary-dark !important;
    }
  }
}

.addnew-lead,
.lead-data-box {
  .p-avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 8px;
  }
  .user-list {
    max-height: 400px;
    overflow-y: auto;
  }
}

.p-inputtext {
  &:focus {
    box-shadow: $shadow-sm !important;
  }
}

.auto-width {
  width: fit-content !important;
}
.indicator-dot {
  background: #ff583e;
  width: 8px;
  right: 0;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
}

.sidebar {
  .sent-btn {
    position: absolute;
    right: 10px;
    bottom: 15px;
    padding-left: 1px !important;
    padding-right: 4px !important;
  }
}

.text-message-wrap,
.sidebar {
  .msg-container {
    padding: 10px;
    height: 325px;
    overflow: auto;
    margin-bottom: 20px;
    border-bottom: 1px solid $black-300;

    .msg-box {
      background-color: $primary-main !important;
      color: $white;
      height: 100%;
      min-width: 25%;
    }

    video {
      width: 100%;
      height: 150px;
    }
  }

  .msg-send-btn {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  .p-avatar {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
  }

  .msg-dtls {
    // height: 350px;
    // height: 100%;
    background-color: $background !important;
  }

  .p-card-title {
    font-size: $size-14 !important;
    @extend .caption-bold;
  }

  .reply-chat {
    .reply-body {
      justify-content: start !important;
    }

    .user-profile {
      justify-content: start !important;
    }

    .chat-attachments {
      justify-content: start !important;

      .shadow-small {
        background-color: $primary-bg !important;
        color: $black-600;
      }
    }

    .msg-box {
      background-color: $primary-bg !important;
      color: $black-600;
    }
  }

  .chat-attachments {
    display: flex;
    justify-content: end;
    align-items: flex-start;

    .shadow-small {
      background-color: $primary-main !important;
      color: $white;

      i {
        color: $white;
      }
    }
  }

  .user-dtls {
    display: flex;
    justify-content: space-between !important;
    // min-width: 25%;//todo - this line was commented to re-arrange the UI in Messages/Text-Message
    align-items: flex-end;
  }

  .msg-filter {
    border-bottom: 1px solid #ccc;
    line-height: 0.1px;
    margin: 15px 0;

    span {
      background-color: #fff;
      padding: 0 10px;
      font-size: $size-12;
    }
  }

  .msg-accordion {
    a {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.text-bold {
  font-family: 'AvenirNextD';

  span {
    @extend .text-bold;
  }
}

.text-medium {
  font-family: 'AvenirNextM' !important;
}

.contact-tbl {
  a {
    text-decoration: none;

    b {
      font-family: 'AvenirNextD';
      color: $black-900;
      text-transform: capitalize;
    }
  }
}

#popup_menu {
  .p-menuitem-text {
    font-family: 'AvenirNextR';
    color: $black-400;
  }
}

.videoInsert {
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  // z-index: -100;
  background-size: cover;
  overflow: hidden;
}

.icon-color {
  i {
    color: #fff !important;
  }
}

.accordian-header {
  margin-bottom: 0 !important;
  @extend .caption-bold;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.icon-red {
  &::before {
    color: $icon-red;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.search-relation {
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    margin-top: 5px;
  }
  .p-autocomplete .p-autocomplete-multiple-container {
    width: 100%;
  }
  ul {
    width: 320px;
    box-shadow: $shadow-sm;
  }
}

.secondary-error {
  color: $icon-red !important;
}

// .taging {
//   ul {
//     width: 100%;
//     box-shadow: none !important;
//   }
// }
.taging {
  ul {
    width: 100%;
    box-shadow: none !important;

    li {
      input {
        font-family: 'AvenirNextR' !important;
        font-size: $size-14 !important;
      }
    }
  }
}

.navbar-toggler {
  &:focus {
    box-shadow: none;
  }
}

.spinner-container {
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #ffffff5c;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  border: 0;

  .spinner-wrp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
    //background-color: #ededed;
    z-index: 1001;
    position: absolute;
    left: 0;
    right: 0;
  }

  .loader {
    max-width: 4rem;
    width: 50%;
    height: auto;
    stroke-linecap: round;
  }

  circle {
    fill: none;
    stroke-width: 3.5;
    -webkit-animation-name: preloader;
    animation-name: preloader;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-transform-origin: 170px 170px;
    transform-origin: 170px 170px;
    will-change: transform;
  }

  circle:nth-of-type(1) {
    stroke-dasharray: 550;
  }

  circle:nth-of-type(2) {
    stroke-dasharray: 500;
  }

  circle:nth-of-type(3) {
    stroke-dasharray: 450;
  }

  circle:nth-of-type(4) {
    stroke-dasharray: 300;
  }

  circle:nth-of-type(1) {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
  }

  circle:nth-of-type(2) {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  circle:nth-of-type(3) {
    -webkit-animation-delay: -0.45s;
    -moz-animation-delay: -0.45s;
    animation-delay: -0.45s;
  }

  circle:nth-of-type(4) {
    -webkit-animation-delay: -0.6s;
    -moz-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  @-webkit-keyframes preloader {
    50% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes preloader {
    50% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  #loader {
    /* Uncomment this to make it run! */
    /*
         animation: loader 5s linear infinite;
      */

    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 30px);
  }

  @keyframes loader {
    0% {
      left: -100px;
    }

    100% {
      left: 110%;
    }
  }

  #box {
    width: 50px;
    height: 50px;
    animation: animate 0.7s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    z-index: 99999;

    .logo-lawft {
      width: 60px;
    }
  }

  .logo-lawft-text {
    width: 40px;
  }

  @keyframes animate {
    17% {
      border-bottom-right-radius: 3px;
    }

    25% {
      transform: translateY(9px);
    }

    50% {
      transform: translateY(18px) scale(1, 0.9);
      border-bottom-right-radius: 40px;
    }

    75% {
      transform: translateY(9px);
    }

    100% {
      transform: translateY(0);
    }
  }

  #shadow {
    width: 50px;
    height: 5px;
    background: rgb(44, 44, 44);
    opacity: 0.1;
    position: absolute;
    top: 59px;
    left: 5px;
    border-radius: 50%;
    animation: shadow 0.7s linear infinite;
    z-index: 99999;
  }

  @keyframes shadow {
    50% {
      transform: scale(1.2, 1);
    }
  }

  .logo {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    width: 60px;
    height: 85px;
    justify-content: center;
    align-items: end;
    z-index: 99999;
  }
}
.rotate-90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.notification-overlay {
  padding: 5px !important;
}
.p-overlaypanel-content {
  .notification-head,
  .notification-footer {
    padding: 8px 15px !important;
  }
  .notification-msg,
  .unread-notification {
    padding: 14px 8px !important;
    border: none !important;
  }
  .notification-wrapper {
    background-color: $white !important;
    max-height: 540px;
    overflow: auto;
    padding: 0px !important;

    .notification-unread {
      background-color: #eff4ff !important;
      border-radius: 8px;
      padding: 0px !important;
      margin-bottom: 5px;
    }
    .notification-read {
      background-color: #fafbfe !important;
      border-radius: 8px;
      padding: 0px !important;
      margin-bottom: 5px;
    }
    .p-avatar-circle {
      border-radius: 50% !important;
    }

    & div {
      margin: 0;
      padding: 0px;
    }

    .p-avatar {
      background-color: $primary-main !important;
      color: #fff;
    }
    .mark-read {
      margin-left: auto !important;
    }
  }
}

.p-tooltip {
  max-width: 280px !important;
  //background-color: $primary-bg !important;
  .p-tooltip-text {
    background-color: $white !important;
    color: $black-700 !important;
    box-shadow: $shadow-sm !important;
    font-size: $size-12 !important;
    border-radius: $size-8 !important;
  }

  .p-tooltip-arrow {
    //border-top-color: $primary-dark !important;
    border: none !important;
  }
}

.update-tag {
  .p-chips-token {
    margin-top: 0 !important;
  }
}

.p-confirm-dialog {
  min-width: 550px;
  .p-dialog-title {
    font-size: $size-16 !important;
  }
}

.p-dialog.p-confirm-dialog {
  .p-confirm-dialog-icon {
    font-size: 1.4rem !important;
  }
}

.report-card {
  .card-title {
    background: #f0f2f5;
    border-radius: 8px;
    padding: 7px;
    margin-right: 8px;
  }

  .dashboard-dropdown {
    width: 108px !important;
  }
}

.mx-w-250 {
  max-width: 250px;
}

.d-inline-table {
  display: inline-table;
  //display: flex;
}

@media screen and (min-width: 40em) {
  .w-md-75 {
    width: 75%;
  }

  .w-md-50 {
    width: 50%;
  }
}
.template-title-table {
  @media screen and (min-width: 1021px) {
    width: 40%;
  }
}
.input-wrap-template {
  .p-inputtext {
    .label {
      white-space: no-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
@media screen and (min-width: 768px) {
  .input-wrap-template {
    .p-inputtext {
      width: auto;
      min-width: 170px;
      max-width: 220px;
    }
  }
  .input-gap {
    padding-right: 10px;
  }
  .text-end-sm {
    text-align: right !important;
  }
  .time-entry-billing {
    width: 17% !important;
  }
  .time-entry-billable {
    width: 10% !important;
  }
  .time-entry-delete {
    width: 5% !important;
  }

  .fileManager-name {
    width: 28% !important;
  }
  .fileManager-sign {
    width: 10% !important;
  }
  .fileManager-actions {
    // width: 125px !important;
    width: 180px !important;
    margin-right: 10px !important;
    text-align: left !important;
    .p-column-title {
      float: none !important;
    }
    span {
      float: right;
    }
  }
  .side-nav {
    position: fixed;
    left: 0;
  }

  .case-dm,
  .settings-dm {
    position: absolute;
    right: 0;
    //overflow: auto;
    padding-right: 20px !important;
  }

  // .settings-dm{
  //   position: absolute;
  //   right: 0;
  //   padding-right: 20px !important;
  // }

  .case-dm {
    .billing-case {
      .search-wraper {
        .input-search {
          width: 215px !important;
        }
      }
    }
  }
  .text-message-height {
    height: calc(100% - 20px) !important;
  }
}

@media print {
  .print-important {
    .p-chart canvas {
      width: 100% !important;
    }
  }

  .p-datatable-scrollable-body {
    max-height: none !important;
  }

  .row {
    break-inside: avoid;
  }
}

.lead-table {
  .p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #e9ecef !important;
  }
}

.run-report-popup {
  //max-width: 40ch;

  h5.sub-title {
    color: $black-600;
  }

  .border-line {
    border-top: 2px solid $black-200;
  }

  .field-checkbox {
    display: flex;
    align-items: center;

    .p-checkbox {
      margin-right: 7px;
    }
  }

  .pi-spin {
    color: #000 !important;
  }
}

.card-layout {
  border-radius: 12px !important;
  margin-bottom: 10px;
}

.file-shortcut-button {
  height: 140px !important;
  width: 140px !important;
  border: 0 !important;
  box-shadow: $shadow-sm;
  background-color: $white;

  i {
    font-size: 35px;
    margin-bottom: 10px;
  }
}

.inline-toast-override {
  color: rgb(12, 54, 91) !important;
  background-color: rgb(232, 243, 252) !important;
  width: 100% !important;
  justify-content: left !important;
  padding: 0.8rem 0.8rem !important;

  .p-inline-message-text {
    font-size: 14px !important;
  }
}

.p-datatable-scrollable-body {
  overflow-y: auto !important;
}

.card-layout.contacts {
  .p-card-content {
    padding-top: 0;
  }

  .custom-header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
}

.contact-duplication {
  .type-in {
    min-width: 336px !important;
  }
}

.filepreview {
  .p-carousel-item {
    .preview-container {
      display: flex;
      flex-direction: column;
      background-color: #f0f2f5;
      padding: 10px;
      border-radius: 16px;

      img {
        width: 50% !important;
        margin: 0 auto;
      }
    }
  }
}

.note-container {
  .p-field-radiobutton {
    display: flex;

    input {
      width: auto !important;
    }
  }
}

.custom-carousel {
  .p-carousel-item {
    padding: 20px 10px !important;
  }

  .time-manager {
    min-height: 370px !important;
    //width: 350px;
    margin: 0 15px !important;
  }
}
.footer-pb {
  padding-bottom: 70px !important;
}

@media (max-width: 767.98px) {
  .input-gap {
    margin-bottom: 10px;
  }
  .resp-flex {
    display: flex !important;
  }
  .r-me-0 {
    margin-left: 0px;
  }
  .r-pb-8 {
    padding-bottom: 8px !important;
  }
  .r-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .r-pe-0 {
    padding-right: 0px !important;
  }
  .r-mb-0 {
    margin-bottom: 0px !important;
  }
  .r-pb-0 {
    padding-bottom: 0px !important;
  }
  .r-ps-0 {
    padding-left: 0px !important;
  }
  .p-dropdown-item {
    max-width: 100% !important;
  }
  .p-sidebar-right {
    width: 95% !important;
  }
  .conversation-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .container-login {
    .p-card {
      width: 25em !important;
    }
  }

  .login-wrap {
    .p-card {
      padding: 0 !important;
    }
  }

  .sign-up-form {
    padding: 0 !important;
  }

  .custom-carousel {
    .p-carousel-item {
      padding: 20px 5px !important;
    }
    .time-manager {
      margin: 0 !important;
    }

    .plan-features {
      label {
        font-size: $size-12;
      }
    }
  }
}
@media (max-width: 480px) {
  .add-contact-name {
    width: 85px !important;
  }
}
@media (max-width: 991px) {
  .case-notes-assign {
    display: block !important;

    .required-switch {
      margin-right: auto !important;
      margin-left: 0px !important;
      width: max-content !important;
      margin-bottom: 5px;

      label {
        padding-left: 0px !important;
      }
      .p-inputswitch-slider {
        width: inherit !important;
      }
    }
  }
  .filter-label {
    margin-right: auto !important;
  }
  .p-overlaypanel {
    width: 98% !important;
  }
  .p-toast-bottom-right {
    right: 15px;
  }
  .p-toast {
    max-width: calc(100% - 30px) !important;
    width: max-content !important;
  }
  .automation-filter,
  .report-filters {
    .error-log-filters,
    .report-filter-wrapper {
      width: 100% !important;
      margin: 0px !important;
    }
    .refresh-icon {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
  .navbar {
    .container-fluid {
      justify-content: flex-start !important;
      display: flex !important;
      padding: 0;

      .add-button {
        border: 0 !important;
        padding: 0 !important;
        width: auto;

        .p-button-icon {
          margin: 0;
        }

        .p-button-label {
          display: none;
        }
      }

      .timer-wrap {
        position: fixed;
        background-color: #fff !important;
        right: 0;
        bottom: 0;
        margin-bottom: 20px;
        box-shadow: $shadow-sm !important;
        z-index: 99;

        label {
          margin: 0 !important;
        }
      }

      .navbar-toggler {
        padding: 0 5px !important;

        .navbar-toggler-icon {
          width: auto;
          padding: 0;
          background-image: none !important;

          .fa-bars {
            transform: scale(1.1, 1);
            width: 24px;
          }

          .add-button {
            border: 0 !important;
            padding: 0 !important;

            .p-button-icon {
              margin: 0;
            }

            .p-button-label {
              display: none;
            }
          }

          .timer-wrap {
            background: none !important;
            box-shadow: none !important;

            label {
              margin: 0 !important;
            }
          }

          .navbar-toggler {
            padding: 0 5px !important;

            .navbar-toggler-icon {
              width: auto;
              padding: 0;
            }
          }
        }
      }

      .case-dm {
        padding-left: 0 !important;
        //margin: 20px;

        .client-btn {
          display: flex;
        }

        .case-contact .p-card .p-card-content {
          margin-bottom: 15px;
        }

        .text-message-wrap,
        .message-wrap {
          padding: 0 !important;
        }
      }

      .file-manager-wrap {
        .filtersearch {
          width: 100%;
          margin: 0 !important;
        }

        .FM-filter {
          .filter-wrap {
            flex-direction: column;
            align-content: flex-start;
            width: 100%;

            .filter-wraper {
              width: 100% !important;
              padding: 8px 0;

              .p-dropdown {
                width: 100%;
              }
            }
          }
        }
      }

      .templates-wrap {
        .filtersearch {
          width: 100%;
          margin: 0 !important;
          margin-top: 10px !important;
        }

        .filter-wrap {
          padding: 5px;

          .multiselect {
            width: 100%;
          }
        }
      }

      .full-calendar-wrap {
        .filter-wrap {
          flex-direction: column;
          align-content: flex-start;
          width: 100%;

          .filter-wraper {
            width: 100% !important;
            padding: 8px 0;

            .p-dropdown {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .templates-wrap {
    .filtersearch {
      width: 100%;
      margin: 0 !important;
      margin-top: 10px !important;
    }

    .filter-wrap {
      padding: 5px;

      .multiselect {
        width: 100%;
      }
    }
  }

  .lead-filter {
    .filter-wrap {
      flex-direction: column;
      align-content: flex-start;
      width: 100%;
    }

    .filter-wraper {
      width: 100% !important;
      flex-direction: column;
      align-content: flex-start;

      .lead-filter {
        width: 100% !important;
        padding: 8px 0;
        margin: 0 !important;

        .p-multiselect {
          width: 100% !important;
        }
      }
    }
  }

  .full-calendar-wrap {
    .filter-wrap {
      flex-direction: column;
      align-content: flex-start;
      width: 100%;
      padding-top: 5px;
      margin-bottom: 1rem;

      .p-dropdown {
        width: 100%;
        margin: 0 !important;
      }
    }

    .Calendar-wrap {
      .fc-header-toolbar {
        flex-direction: column;
      }

      .fc-toolbar-chunk {
        padding: 5px 0;
      }
    }
  }

  .billing-wrap {
    .p-input-icon-left {
      width: 100% !important;

      .input-search {
        width: 100% !important;
      }
    }

    .search-wraper {
      width: 100%;
    }

    .billing-filter {
      flex-direction: column;
      align-content: flex-start;
      width: 100%;

      .filter-wraper {
        width: 100%;
        margin: 0 !important;
        padding: 8px 0;

        .p-dropdown {
          width: 100%;
          margin: 0 !important;
        }
      }

      .Calendar-wrap {
        .fc-header-toolbar {
          flex-direction: column;
        }
      }
    }

    .billing-wrap {
      .p-input-icon-left {
        width: 100% !important;

        .input-search {
          width: 100% !important;
        }
      }

      .search-wraper {
        width: 100%;
      }

      .billing-filter {
        flex-direction: column;
        align-content: flex-start;
        width: 100%;

        .filter-wraper {
          width: 100%;
          margin: 0 !important;
          padding: 8px 0;

          .p-dropdown {
            width: 100%;
            margin: 0 !important;
          }
        }
      }
    }
  }

  .task-filter {
    flex-direction: column;
    align-content: flex-start;
    width: 100%;
    margin-bottom: 15px;

    .p-dropdown {
      width: 100%;
      margin: 8px 0;
    }
  }

  .task-wraper {
    flex-direction: column;

    .filter-wrap {
      flex-direction: column;
      align-content: flex-start;
      width: 100%;
    }

    .filter-wraper {
      flex-direction: column;
      width: 100%;

      .p-dropdown {
        width: 100% !important;
        margin: 8px 0 !important;
      }
    }
  }

  .filter-wraper {
    flex-direction: column !important;
    width: 100%;
    margin-left: 0 !important;

    .p-dropdown,
    .p-multiselect,
    .p-calendar {
      width: 100% !important;
      margin: 8px 0 !important;
    }
  }

  .mx-w-250 {
    max-width: 100% !important;
  }

  .zeropadding {
    padding: 0 !important;
  }

  // .contact-wrap{
  //   .filter-wraper{
  //     padding-left: 0.5rem !important;
  //   }
  // }

  .login-dropdown {
    width: auto !important;
  }

  #popup_menu {
    left: 8px !important;
    width: 95% !important;
  }

  .filter-label {
    width: 97%;
    padding: 5px;
    margin-top: 10px;
  }

  .new-task-popup,
  .run-report-popup,
  .create-reminder-popup,
  .mange-colum-modal,
  .modal-custom-field {
    width: 100% !important;
  }

  // #popup_menu {
  //   left: 0 !important;
  //   width: 100% !important;
  // }

  .timer-container {
    display: flex;
    align-items: center;
  }

  .navbar-collapse {
    position: absolute;
    background: #fff;
    width: 100%;
    z-index: 999;
    top: 50px;
    padding: 10px !important;
    left: 0;
    box-shadow: $shadow-sm;
  }

  .file-manager-wrap {
    .filtersearch {
      width: 100% !important;
      margin: 0 !important;
    }

    .FM-filter {
      .filter-wrap {
        flex-direction: column;
        align-content: flex-start;
        width: 100%;

        .filter-wraper {
          width: 100% !important;
          padding: 8px 0;

          .p-dropdown {
            width: 100%;
          }
        }
      }
    }
  }

  .case-details-wrap {
    .filter-wrap {
      .flex-wrap {
        flex-direction: column;
        align-content: flex-start;
        width: 100%;

        .filter-wrap {
          width: 100%;
          padding: 8px 0;

          .p-dropdown {
            width: 100%;
          }
        }
      }

      .filter-label {
        width: 97%;
        padding: 5px;
        margin-top: 10px;
      }
    }
  }

  .p-sidebar-left.p-sidebar-lg,
  .p-sidebar-left.p-sidebar-md,
  .p-sidebar-right.p-sidebar-lg,
  .p-sidebar-right.p-sidebar-md {
    width: 25rem !important;
  }

  .addcase-popup {
    .sub-title {
      font-size: 14px !important;
    }
  }

  .swich-w {
    .p-inputswitch-slider {
      width: 37.5px !important;
    }
  }

  .autowidth {
    width: 100% !important;

    .input-search {
      width: 100% !important;
    }
  }

  .contact-duplication .type-in {
    min-width: 315px !important;
  }

  .dirction-column {
    flex-direction: column !important;
    width: 100%;
  }

  .addnew-modal,
  .p-dialog {
    width: 100% !important;
    min-width: 100% !important;
  }

  .global-search {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
    justify-content: end;
    top: 75px;
    margin: 5px;

    .search {
      width: 98.5% !important;
      visibility: visible !important;
    }

    .search-container {
      width: 100% !important;
    }

    .searchbutton {
      padding-right: 15px !important;
      right: 0;
    }

    .open {
      width: 98% !important;
    }
  }

  .content-container {
    margin-top: 45px;
  }

  .time-manager-filter {
    justify-content: center !important;
    padding: 10px 0;
    .filter-wrap {
      flex-direction: column;
      align-content: flex-start;
      width: 100%;
    }

    .p-dropdown,
    .p-multiselect {
      width: 100% !important;
    }
  }
}

.input-style {
  box-shadow: $shadow-sm;
  border-radius: 12px;
  padding: 8px;
  background: #fff;
}

.li-style-none {
  list-style: none;
}

.timeentry-popup {
  //background-color: $white !important;

  div {
    //background-color: $white !important;
    margin: 0 !important;
    border-radius: 0;
  }

  .p-checkbox-box,
  .p-checkbox,
  .p-checkbox-box {
    padding: 0 !important;
    border-radius: 0 !important;
  }

  .p-dropdown-label {
    padding: 3px !important;
  }

  .time-entry-wrp {
    padding: 0 !important;
  }

  .margin-top {
    margin-top: $size-16 !important;
  }
}

.subtaskBox {
  padding: 5px 8px;
  border-radius: 12px;
  box-shadow: $shadow-sm;
}

.client-task {
  .subtask-title {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.0025em;
    color: $black-900;
  }

  .sub-title {
    //styleName: body/hint;
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: $black-500;
  }

  .expand-icon {
    width: 1rem !important;
    height: 1rem !important;
    padding: 10px 10px !important;
    margin-left: 5px;
    color: #ced4da !important;
  }

  .sub-box {
    width: fit-content;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    padding: 3px 8px;
  }

  .prioritys {
    border-radius: 12px;
    padding: 5px 10px;

    &.medium {
      color: #f8955d;
      background: #fff5f2;
    }

    &.high {
      color: #ff583e;
      background: #fff5f2;
    }

    &.low {
      color: #4ba457;
      background: #edfaef;
    }

    &.priority {
      color: #7b7b7b;
      background: #f7f7f7;
    }
  }
}

.column-center {
  flex-direction: column;
  align-items: center;
}

.icon-next-btn {
  background: #517dda;
  padding: 9px;
  border-radius: 6px;
}

.timer-container {
  display: flex;
  align-items: center;
}

.react-tagsinput-tag {
  background-color: $black-100 !important;
  border: 0 !important;
  border-radius: $size-8 !important;
  color: $black-700 !important;
  font-family: 'AvenirNextR' !important;

  .react-tagsinput-remove {
    color: $black-700 !important;
    text-decoration: none;
  }
}

.p-multiselect-token {
  background-color: $black-100 !important;
  border: 0 !important;
  border-radius: $size-8 !important;
  color: $black-700 !important;
}

.popup-input {
  width: auto !important;
}

.W-Auto {
  .p-dropdown-label {
    width: auto !important;
  }
}

.duedate {
  .p-button {
    background: none !important;
    border: 0 !important;
  }
}

.report-wraper {
  .p-calendar-w-btn .p-inputtext {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.p-dropdown-items-wrapper {
  .p-dropdown-items {
    .p-dropdown-item {
      cursor: pointer !important;

      &:hover {
        background-color: $primary-bg !important;
      }
    }
  }
}
.advanced-search {
  .input-shadow {
    position: relative;
  }
  .icon-search {
    position: absolute;
    right: 15px;
    top: 12px;
  }
  .search {
    position: absolute;
    left: 49px;
    background-color: white;
    outline: none;
    border: none;
    padding: 0;
    width: 0;
    height: 38px;
    top: -19px;
    right: -16px;
    padding-left: 12px !important;
    // z-index: 10;
    transition-duration: 0.4s;
    visibility: hidden;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    padding: 0 16px 0 0;
  }
  .icon-files {
    z-index: 99;
    top: 9px;
    right: 42px;
    position: absolute;
    background-color: #eee;
    padding: 4px 2px;
    border-radius: 4px;
    font-size: 16px;
  }

  .icon-files.highlight {
    background-color: #517ddd;
  }
  .icon-files.highlight:before {
    color: #fff;
  }
}
.global-search {
  .search-container {
    position: relative;
    display: flex;
    height: 38px;
    width: 20px;
    justify-content: center;
    align-items: center;
  }

  .searchbutton {
    position: absolute;
    margin: 0;
    padding: 0;
    line-height: 0;
  }

  .search:focus + .searchbutton {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: white;
    color: black;
    z-index: 500;
  }

  .search {
    position: absolute;
    left: 49px;
    background-color: white;
    outline: none;
    border: none;
    padding: 0;
    width: 0;
    height: 38px;
    top: -19px;
    right: -16px;
    padding-left: 12px !important;
    // z-index: 10;
    transition-duration: 0.4s;
    visibility: hidden;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    padding: 0 16px 0 0;
  }
  .icon-files {
    z-index: 99;
    right: 28px;
    position: absolute;
    background-color: #eee;
    padding: 4px 2px;
    border-radius: 4px;
    font-size: 16px;
  }
  .icon-files.highlight {
    background-color: #517ddd;
  }
  .icon-files.highlight:before {
    color: #fff;
  }

  .expandright {
    left: auto;
    right: -15px;
    /* Button width-1px */
    padding: 0 0 0 16px;
  }

  .result-list {
    min-height: 10px;
    max-height: 60vh;
    overflow: auto;
  }

  .result {
    border-radius: 10px;
    visibility: hidden;
    position: absolute;
    top: 40px;
    right: 0;
    background: white;
    padding: 15px;
    width: 0;
    // min-height: 10px;
    // max-height: 60vh;
    z-index: 1000;
    overflow-y: auto;
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    box-shadow: $shadow-sm;

    .list {
      li {
        list-style: none;
      }

      li:nth-child(even) {
        background-color: #fafbfe;
      }

      .title {
        font-size: 18px;
        line-height: 18px;
        padding: 5px 7px;
      }

      .sub-title {
        line-height: 14px;
        font-size: 14px;
        padding: 5px 7px;
        color: #677a8e;
        cursor: pointer;
        font-family: 'AvenirNextR' !important;
      }

      border-bottom: 1px solid rgb(223, 225, 230);
      margin-bottom: 10px;
      padding-bottom: 5px;
    }

    .list:last-child {
      border-bottom: none;
    }
  }

  .open {
    width: 363px;
    visibility: visible;
    margin-right: -5px;
    word-break: break-all;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .case-details-wrap {
    .filter-wrap {
      .flex-wrap {
        flex-direction: column;
        align-content: flex-start;
        width: 100%;

        .filter-wrap {
          width: 100%;
          padding: 8px 0;

          .p-dropdown {
            width: 100%;
          }
        }
      }

      .filter-label {
        width: 97%;
        padding: 5px;
        margin-top: 10px;
      }
    }
  }
  .filter-label {
    margin-right: auto !important;
  }
  .autowidth {
    width: 100% !important;

    .input-search {
      width: 100% !important;
    }
  }

  .full-calendar-wrap {
    .filter-wrap {
      flex-direction: column;
      align-content: flex-start;
      width: 100%;

      .filter-wraper {
        width: 100% !important;
        padding: 8px 0;

        .p-dropdown {
          width: 100%;
        }
      }
    }
  }

  #popup_menu {
    left: auto !important;
    width: 50% !important;
    right: 25px !important;
  }

  .filter-wraper {
    flex-direction: column !important;
    width: 100%;
    margin-left: 0 !important;

    .p-dropdown,
    .p-multiselect,
    .p-calendar {
      width: 100% !important;
      margin: 8px 0 !important;
    }

    .input-search {
      width: 100% !important;
    }
  }

  .task-wraper {
    flex-direction: column;

    .filter-wrap {
      flex-direction: column;
      align-content: flex-start;
      width: 100%;
    }

    .filter-wraper {
      flex-direction: column;
      width: 100%;

      .p-dropdown {
        width: 100% !important;
        margin: 8px 0 !important;
      }
    }
  }

  .lead-filter {
    .filter-wrap {
      flex-direction: column;
      align-content: flex-start;
      width: 100%;
    }

    .filter-wraper {
      width: 100% !important;
      flex-direction: column;
      align-content: flex-start;

      .lead-filter {
        width: 100% !important;
        padding: 8px 0;
        margin: 0 !important;

        .p-multiselect {
          width: 100% !important;
        }
      }
    }
  }

  .media-popup {
    width: 70vw;
  }
  .automation-filter,
  .report-filters {
    .error-log-filters,
    .report-filter-wrapper {
      width: 100% !important;
      margin: 0px !important;
    }
    .p-multiselect-token {
      width: auto !important;
    }
  }
  .refresh-icon {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.tbl-title {
  color: $black-800 !important;
}

.tbl-avatar {
  .p-avatar {
    width: 24px;
    height: 24px;
    border-radius: 8px !important;

    img {
      border-radius: 8px !important;
    }
  }
}

.white-icon::before {
  color: $white !important;
}

.rfipicons__pager {
  display: none !important;
}

.rfipbtn__icon--empty {
  text-transform: capitalize !important;
  font-style: normal !important;
  font-size: 15px !important;
  color: #495057 !important;
}

.expensetype-wrp {
  .rfipdropdown {
    position: relative !important;
    border-radius: 12px !important;
    border: 0 !important;
  }
}

.sketch-picker {
  //position: absolute;
  z-index: 9 !important;
}

.user-details {
  .sketch-picker {
    position: absolute;
  }
}

.btn-switch {
  .p-button {
    font-weight: 400 !important;
    padding: 9px !important;
    border: 0 !important;
    border-radius: 12px;
  }

  .p-highlight {
    background-color: #eff4ff !important;
    color: $primary-main !important;
    font-family: 'AvenirNextM' !important;
  }
}

.case-dm {
  .text-message-wrap,
  .message-wrap,
  .file-manager-wrap,
  .full-calendar-wrap {
    padding: 0 !important;
  }
}

.p-toast {
  .p-toast-message {
    background-color: $white !important;
    border: 0 !important;
    border-radius: $size-12 !important;
    box-shadow: $shadow-md !important;

    .p-toast-message-text {
      color: $black;
    }

    .p-toast-summary {
      font-weight: 500 !important;
      font-size: $size-16 !important;
    }

    .p-toast-details {
      font-size: $size-12 !important;
    }
  }

  .p-toast-message-error {
    .p-toast-message-icon {
      color: $error !important;
    }

    .p-toast-icon-close {
      color: $black-500 !important;
    }
  }

  .p-toast-message-success {
    .p-toast-message-icon {
      color: $success !important;
    }

    .p-toast-icon-close {
      color: $black-500 !important;
    }
  }
}

.p-component {
  .form-label {
    font-family: 'AvenirNextR' !important;
    color: #495057 !important;
  }
}

.p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext {
  color: #6c757d !important;
  font-size: $size-14 !important;
}

.firm-user-select {
  box-shadow: none;

  &::after {
    content: '';
    position: absolute;
    background: $primary-dark !important;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: -1;
  }
}

.p-breadcrumb {
  background: none !important;
  border: 0 !important;
  padding-left: 5px !important;

  .p-menuitem-link {
    color: $black-500 !important;
  }
}

.avatarcurved {
  .p-avatar {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    font-size: $size-12 !important;

    img {
      border-radius: $size-8 !important;
    }
  }
}

// rc-year-calendar - overriding the default style
.calendar .months-container .month-container {
  margin-bottom: 36px !important;
}

// .month-container {
//   // position: relative !important;
//   .day {
//     position: relative !important;
//     .day-content::after {
//       content: "";
//       position: absolute;
//       background: #2655b9 !important;
//       width: 46px;
//       height: 46px;
//       border-radius: 50%;
//       // z-index: -1;
//     }
//   }
// }

.event-tooltip-content {
  padding: 5px;
  max-height: 350px;
  overflow: auto;
}

// .event-tooltip-content:not(:last-child) {
//   border-bottom: 1px solid #ccc;
// }
.fc-multiMonthYear-view .fc-daygrid-day-number:hover,
.fc-dayGridMonth-view .fc-daygrid-day-number:hover {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

//tippy tooltip customization
.tippy-box {
  box-shadow: $shadow-sm;
  background-color: $white !important;
  border-radius: $size-12 !important;
  color: $black-800 !important;

  .create-date {
    font-size: $size-16;
    font-family: 'AvenirNextD' !important;
    text-align: center;
    color: $black-500 !important;
  }

  .event-details {
    display: flex;
    // flex-direction: row-reverse;
    padding: 6px;
    background-color: $background;
    border-radius: $size-8;
    margin: 4px 0 8px 0;
    //justify-content: space-between;
    align-items: center;

    .event-name {
      padding: 4px 10px;
      // background-color: $turquoise;
      border-radius: $size-8;
      font-size: $size-12;
      font-family: 'AvenirNextR';
      cursor: pointer;
    }

    .event-time {
      padding: 4px 10px;
      margin-right: 10px;
      font-size: $size-14;
      font-family: 'AvenirNextR';
      display: flex;
      align-items: center;
      min-width: 100px;
    }
  }
}

.calendar {
  .month {
    .day-header {
      font-size: $size-12 !important;
      font-family: 'AvenirNextM' !important;
      font-weight: 100 !important;
    }

    .month-title {
      font-size: $size-14 !important;
      font-family: 'AvenirNextM' !important;
      font-weight: 100 !important;
    }
  }
}

.input-line-height {
  line-height: 2.5;
}

.upcoming-date {
  i {
    border: 1px solid $primary-main;
    padding: $size-4;
    border-radius: $size-8;
    color: $primary-main;
  }
}

.bg-color {
  background-color: $background !important;
}

.select-btn {
  .p-selectbutton {
    .p-button {
      border-radius: $size-12;
      border: none;
    }

    .p-highlight {
      background-color: $primary-bg !important;
      color: $primary-main !important;
      font-family: 'AvenirNextM' !important;
    }
  }
}

//rc-year-calendar day hover style
.calendar {
  .month-container.month-2 {
    .month {
      .day {
        .day-content:hover {
          background: none !important;
        }
      }
    }
  }
}

@media screen and (min-width: 991.98px) {
  .filter-wraper {
    .p-multiselect {
      max-width: 220px;
    }
    .p-multiselect-items-label {
      max-width: 90%;
      padding: 0.25rem !important;
      height: 40px;
      align-items: center;

      .p-multiselect-token {
        max-width: 100%;
      }
      .p-multiselect-token-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .notes-status {
    width: 20% !important;
  }
  .contact-email {
    width: 200px !important;
  }
  .contact-actions {
    width: 200px !important;
  }
  .contac-lawft-access {
    width: 100px !important;
  }
  .col-lg-45 {
    width: 45% !important;
  }
  .error-log-validation {
    margin-left: 33.33333%;
  }
  .case-dm {
    .casefile-wrap {
      .col-lg-2 {
        width: 20% !important;
      }

      i {
        cursor: pointer;
      }
    }
  }

  .navbar-expand-lg {
    .navbar-nav {
      .nav-link {
        padding-right: 0.75rem !important;
        padding-left: 0.75rem !important;
      }
    }
  }

  .contact-wrap {
    .search-bar {
      width: 320px !important;
      border-radius: $size-8;
    }
  }
}

//------- add global-tag  for case module -----//
.tag-highlight {
  padding: 0.25rem 0.5rem;
  margin-right: 10px;
  background: #f0f2f5;
  color: #495057;
  border-radius: 5px;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }
}

.global-tag-wrapper {
  padding: 10px;
  border: 1px solid #fff;
  display: flex;
  flex-wrap: wrap;
}

.selected-global-tag {
  background: #878d96;
  color: white;
}

.form-control,
.input-shadow {
  &:focus {
    box-shadow: $shadow-sm;
  }
}

.welcome-lawft {
  .p-card-title {
    text-align: center;
    font-family: 'AvenirNextD' !important;
    font-size: 1.2rem;
  }

  .p-card-subtitle {
    text-align: center;
  }
}

#card-element {
  padding: 10px !important;
}

.black-800 {
  color: $black-800 !important;

  &::before {
    color: $black-800 !important;
  }
}

.icon-bg {
  width: 24px;
  height: 24px;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    color: $primary-main !important;
  }
}

.p-focus {
  box-shadow: none !important;
}

.p-autocomplete-multiple-container {
  width: 100%;
  input {
    font-size: $size-14 !important;
  }
}

.lead-info {
  .billing-preferences {
    .p-inputwrapper,
    .p-inputtext {
      width: 100% !important;
    }
  }
}

.disabledCursor {
  cursor: default !important;
}

:fullscreen {
  background-color: #fff;
}

.subscribe-msg {
  background-color: $primary-dark;
  color: #fff;
  height: 45px;
  box-shadow: $shadow-md;
}

.sendto-back {
  color: $black-400 !important;
}

.timemanager-notification {
  background-color: $background !important;
}

.open-timemanager-popup {
  width: 470px !important;
  .p-dialog-title {
    font-weight: 500;
    font-size: 14px !important;
    letter-spacing: 0.0025em;
    color: #7f8f9f;
  }
  .p-dialog-header {
    padding-bottom: 0.25rem !important;
  }
}

.border-radius-50 {
  border-radius: 50% !important;
}

.p-datatable .p-datatable-tbody .p-avatar {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  display: inline-block;
  text-align: center;
  position: relative;
  bottom: 0px;
  font-size: 12px !important;
  line-height: 2;
  border-radius: $size-8;
  align-items: center;
  display: flex;
}

.text-muted-gray {
  color: lightgray;
}

// ============== dashboard new layout change styles  ===============

.display-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.item-count {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  // color: #f0f2f5;
  border-radius: 5px;
  text-align: center;
  vertical-align: center;
  border: 1px solid #3132335e;
  color: #3132335e;
}

.bg-grey {
  background: #f0f2f5;
}

.details-container {
  padding: 24px 12px 12px 12px;
  border-radius: 10px;
  height: calc(100% - 15px);
}

.needresolution-container {
  padding: 24px;
  border-radius: 10px;
  height: calc(100% - 15px);
}
.casename-container {
  border-radius: 10px;
  padding: 5px;
  width: fit-content;
}
.fw-bolder {
  font-weight: bolder !important;
}
.no-priority {
  color: #11bddc !important;
}
.dashboard-priority-dropdown,
.task-list {
  .icon-Error:before {
    color: #11bddc !important;
  }
  .priority-dropdown {
    border-radius: $size-8 !important;
    line-height: 2;
  }
  .p-dropdown-trigger {
    width: 34px !important;
  }
  .pi-chevron-down {
    font-size: 12px !important;
  }
}
.dashboard-priority-dropdown {
  width: 65px;
  height: 32px;
}

.call-back {
  color: $primary-middle;

  i {
    color: $primary-middle;
  }
  .note {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // width: 80px;
    width: 238px;
    display: inline-block;
  }
}

// .bg-light-green {
//   background: #6de07d4f;
// }
.unread-message-container {
  .message-flag {
    color: #6ebf79 !important;
    background: #6de07d4f;
    padding: 5px;
    border-radius: 10px;
    height: 22px;
    min-width: 50px;
    max-width: fit-content;
    h6 {
      font-size: 12px !important;
    }
  }
}

.elipsis-wrapper {
  @extend .item-count;
  width: 30px;
  height: 30px;
  padding: 4px;

  .pi-ellipsis-v {
    line-height: 1.3;
  }
}

.agenda-details {
  .icon-container {
    border: 1px solid $primary-main;
    font-weight: bold;
    @extend .item-count;
    @extend .display-center;
    @include rounded-8;

    i:before {
      color: $primary-main !important;
    }

    .fa-clock {
      font-size: 8px;
      position: absolute;
      left: 15px;
      bottom: 5px;
      z-index: 2;
      color: $primary-main;
    }
  }
}

.recent-file {
  .icon-container {
    border: 1px solid #7f8f9f;
    padding: 0 3px 0 5px;
    @extend .item-count;
    @extend .display-center;
    @include rounded-8;

    i:before {
      color: $primary-main !important;
    }
  }
}

.item-count-center {
  @extend .item-count;
  @extend .display-center;
  @include rounded-8;
}

.padding-10 {
  padding: 10px !important;
}

//setting min height - dashboard overdue-widget layout
.dashboard-overdue {
  min-height: 145px;
  max-height: 145px;
}

.dashboard-activity {
  @extend .dashboard-overdue;
}
.time-manager {
  @extend .dashboard-overdue;
}

.F-size25 {
  font-size: 25px !important;
}
.F-size18 {
  font-size: 18px !important;
}
.F-size22 {
  font-size: 22px !important;
}

.resolution-details {
  .resolution-card {
    .icon-container {
      border: 1px solid $primary-main;
      //background-color: $primary-main;
      font-weight: bold;
      @extend .item-count;
      @extend .display-center;
      @include rounded-8;

      i:before {
        color: $primary-main;
      }
    }
  }
}

.mssg-reply-bgcolor:nth-child(odd) {
  background-color: $background;
  border-radius: 8px;
  .p-editor-container .p-editor-content .ql-editor {
    background-color: $background;
  }
}

.mssg-img-size {
  display: flex !important;
  align-items: center !important;
}

.shadow-middle.p-card .p-card-content {
  padding: 20px 10px 20px 10px;
}

.invite-user {
  ul {
    width: 100%;
  }

  .p-autocomplete-multiple-container {
    .p-highlight {
      display: none !important;
    }
  }
}

hr {
  background: $black-200;
}

.p-dropdown-filter {
  border-radius: $size-12 !important;
}

.search-bar {
  border-radius: $size-8;
}

.disabled-element {
  background-color: #f9f9f9 !important;
  color: $black-200 !important;
  border: none !important;
  cursor: default !important;
  i {
    color: #d9d9d9 !important;
  }
}

.disable-option {
  color: $black-200 !important;
  border: none !important;
  cursor: default !important;
}

.pi-sort-alt:before {
  font-size: 10px;
  color: #7f8f9f;
}

.download-toast {
  display: flex;
  width: 225px;
  background-color: #fff;
  border-radius: 10px;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0px 2px 6px rgba(103, 122, 142, 0.24);
}

// .tox-edit-area{
//   margin:60px !important;
//   // border-radius: 8px;
// }
// .tox-editor-header{
// display: none !important;
// }

// .tox-sidebar-wrap{
//   background: #d3d3d37a !important;
// }

@media (min-width: 992px) and (max-width: 1445px) {
  .lawfttop-navbar {
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding-right: 0.4rem !important;
          padding-left: 0.4rem !important;
        }
      }
    }
  }

  .input-search {
    //width: 320px !important;
    width: 250px !important;
  }

  .media-popup {
    width: 50vw;
  }
}

@media (min-width: 1445px) {
  .lawfttop-navbar {
    justify-content: end;
  }
}

.p-autocomplete-panel {
  @extend .input-shadow;
}

.success {
  background-color: $success;
  color: $white;
  font-size: $size-12;
  padding: 5px;
  letter-spacing: 1px;
}

.workflow-inactive-pill {
  @extend .success;
  // background-color: #606060;
  background-color: #606060b0;
  // background-color: #e9ecef;
}

.bg-none {
  background: none !important;
  border: none !important;
}

.admin-portal {
  .full-calendar-wrap,
  .message-wrap,
  .client-task {
    padding: 0 !important;
    margin-top: 16px !important;
  }
}

.change-view {
  .highlight {
    background-color: $primary-main !important;

    i {
      color: $white !important;

      &:before {
        color: $white !important;
      }
    }
  }
}

.font-italic {
  font-style: italic !important;
}
//show more button in calendar module
.fc .fc-daygrid-more-link {
  text-decoration: none;
  font-weight: bold;
  font-family: 'AvenirNextD' !important;
}

.generatebtn {
  width: 180px;
}

.medical-expense-popup {
  width: 50vw !important;
}
.filter-calender-case-expense {
  .p-inputtext {
    width: 179px !important;
    height: 40px !important;
  }
}

.p-datascroller {
  .p-datascroller-content {
    background: none !important;
  }
}

.footer-btn {
  border-top: 1px solid #e7eaee;
  padding-top: 15px;
  display: flex;
  margin-top: 15px;
  justify-content: end;
}

.border-top {
  border-top: 1px solid #e7eaee !important;
}
.border-bottom-dark {
  border-bottom: 1px solid $black-200;
}

.color-picker {
  .sketch-picker {
    position: absolute;
  }
}

.conflict-card {
  .p-datascroller-list {
    overflow: auto;
    height: 150px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  //border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(124, 124, 124);
}

.print-important {
  .p-card-title {
    font-size: 14px !important;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #5a6672;
    font-family: 'AvenirNextD';
  }
}

.case-name-inputfield {
  border-bottom: 1px solid #ccc !important;
  border: 0 !important;
  background: #fafbfe !important;
  border-radius: 0 !important;
  font-family: 'AvenirNextD' !important;
  font-size: 24px !important;
  letter-spacing: 0.0025em;
  color: #333c47 !important;

  &:focus {
    box-shadow: none !important;
  }
}

.p-inline-message {
  .p-inline-message-warn {
    background-color: #f8f3e4 !important;
  }
}

.generate-btn {
  width: 160px !important;
}

.convertocase {
  .p-dropdown,
  .p-inputwrapper,
  .p-inputtextarea {
    width: 100% !important;
  }
}

.automation-actions {
  border: 0 !important;
  width: 350px;
  .p-menuitem-link {
    &:hover {
      background: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
  span {
    width: 100%;
  }
}
.automation-folder {
  .add-sub-folder {
    color: #517edb;
  }

  .folder {
    flex-grow: 1;
    padding: 8px;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .folder.selected {
    background: #517edb;
    color: #ffff;
    .pi {
      color: #fff;
    }
    i {
      &:before {
        color: #fff;
      }
    }
  }
  .selected-folder {
    background-color: $primary-bg;
    border-radius: 12px 0 0 12px;
    padding: 5px;
    margin-bottom: 5px;
  }

  .sub-folder-container {
    background-color: $primary-bg;
    border-radius: 0px 12px 12px 0px;
    max-height: 500px;
    overflow: auto;

    .folder {
      background-color: $white;
    }
  }
}
.disclaimer {
  color: #504e4e;
  padding: 10px;
  margin-bottom: 20px;
  font-style: italic;
}

.btn-width {
  width: 70px;
}

.p-dropdown-item-group {
  font-size: $size-12 !important;
  // color: $black-400 !important;//commented due to client requirement
  // font-weight: 100 !important;//commented due to client requirement
  color: $black !important;
  font-weight: bolder !important;
}

.p-paginator {
  .p-dropdown-label {
    width: fit-content !important;
  }
}

.dashboard-link {
  a {
    color: #5a6672 !important;
  }
}

.table-input {
  span {
    box-shadow: none !important;
    border: 0;
    input {
      box-shadow: $shadow-md;
      border: 0;
      border-radius: 8px;
    }
  }
}

.p-breadcrumb-home {
  .p-menuitem-link {
    &:focus {
      box-shadow: none !important;
    }
  }
}

.timespendwraper {
  background-color: #f6f7f9;
  margin: 2px;
  border-radius: 10px;

  // .chour{
  //   border-left: 1px solid #ccc;
  // }
}

.right-border {
  border-right: 1px solid #ccc;
}

.start-conversation {
  font-size: 1.5rem !important;
  color: $black-500;
  font-family: 'AvenirNextD';
  i {
    font-size: 3rem;
  }
}
.black100 {
  color: $black-100;
}
.text-message-height {
  .msg-holder {
    max-height: 75vh;
    overflow: hidden auto;

    @media screen and (max-width: 991px) {
      height: auto !important;
    }
  }
}
.text-message-input,
.text-message-input:focus {
  box-shadow: none !important;
  border: none !important;
  overflow-y: auto !important;
  max-height: 115px !important;
  min-height: 80px !important;
}
.text-message-input-wrapper {
  border: 1px solid #dee2e6 !important;
  box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
  border-radius: 12px;
  padding: 10px;
}
.disabled-checkbox {
  width: 14px !important;
  height: 14px !important;
  border: 1px solid #ced4da !important;
  border-radius: 3px;
}

.mutiselectwidth {
  width: 400px;
}

.msg-case_details_sidebar {
  height: 74vh;
  overflow: auto;
}

.notification-textmsg {
  font-size: 14px;
  padding: 8px 10px;
  background-color: #fafbfe;
  margin: 5px 0;
  border-radius: 10px;

  .message-status {
    .icon-messages,
    .icon-sidebar {
      position: relative;
    }
    .p-badge {
      position: absolute;
      font-size: 8px !important;
      width: auto;
      min-width: 15px !important;
      padding: 2px 3px !important;
      height: 15px !important;
      line-height: normal !important;
      background: #2655b9 !important;
      display: inline-block;
      border-radius: 10px;
      text-align: center;
      top: -5px;
      right: -8px;
      font-family: 'AvenirNextR' !important;
    }
  }
}
.sub_count {
  width: 18px;
  height: 18px;
  background-color: $primary-dark;
  border-radius: 10px;
  text-align: center;
  margin-left: 10px;
  color: $white;
  font-size: 12px;
  padding: 0 5px;
}

.text-black-color {
  color: #333c47;
}

.new-task-popup .p-dialog-content {
  padding-bottom: 0 !important;
}

.disabled-icon {
  pointer-events: none;
  opacity: 0.5;
  /* Add any additional styles to visually indicate it as disabled */
}
.repeated-ActionInput {
  .p-inputnumber-input {
    max-width: 100%;
  }
}
.text-message-files img {
  cursor: default;
  background-color: rgba(168, 168, 168, 0.34);
}
.chat-image {
  height: 160px;
  min-height: 100px;
  max-width: 380px;
  width: auto;
}
.chat-loader-container {
  top: 0;
  padding: 20;
  text-align: center;
  position: absolute;
  z-index: 1;
  width: 100%;

  .overlay {
    width: 45px;
    margin: auto;
    background: white;
    border-radius: 50%;
    height: 45px;
    box-shadow: 0px 2px 6px rgba(103, 122, 142, 0.8) !important;
    padding-top: 8px;
  }
}
.text-message-files {
  position: relative;
  cursor: default;
}
.download-file-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
  border-radius: 2px;
  padding: 3px;
  font-size: 17px;
  cursor: pointer;
  z-index: 9999;
  border: 1px solid #e9e9e9;
}
.download-file-icon:hover {
  opacity: 1;
}
.download-file-icon::before {
  color: #202020;
}
.message-file-icon {
  font-size: 60px;
}
.message-file-container {
  background-color: rgba(168, 168, 168, 0.34);
  display: grid;
  text-align: center;
  max-width: 250px;
  padding: 7px;
  min-width: 110px;
}
.message-file-icon::before {
  color: $black-100;
}

// .Calendar-wrap .fc-daygrid-day .fc-daygrid-day-frame {
//   height: 0px !important;
// }

.fc.fc-theme-standard .fc-view-harness .fc-multiMonthYear-view td {
  border: none !important;
}

.fc .fc-multimonth-multicol .fc-daygrid-more-link {
  border: none !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  max-width: 400px;
  white-space: break-spaces;
}

.capitalize-text {
  text-transform: capitalize;
}
.fix-button {
  padding: 0 15px !important;
}

@media screen and (min-width: 1150px) {
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    max-width: 500px;
    white-space: break-spaces;
  }
  .lengthy-label {
    padding-right: 5px;
    white-space: nowrap;
  }
  .related-contact-action {
    width: 60px;
    text-align: center;
  }
}
.error-log-fix-popup {
  .p-multiselect-token-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .p-multiselect-token {
    width: auto;
  }
  .p-multiselect-label {
    padding: 0.5rem 0.5rem !important;
  }
  .p-multiselect-token {
    margin-top: -5px;
  }
  .p-dialog-content {
    padding-bottom: 0px !important;
  }
}
.toast-contents {
  .icon-Alert::before {
    color: #ff583e !important;
  }
}
.mark-complete-icon {
  font-size: 18px;
  margin-right: 7px;
  cursor: pointer;
}
.mark-as-completed {
  color: $success;
  cursor: inherit;
}
.mark-complete-icon:hover {
  color: $success;
}
.add-relation-container {
  @media (max-width: 768px) {
    .input-search {
      width: 100% !important;
    }
  }
}
.add-contact-name {
  width: 170px;
}

.p-editor-container .p-editor-toolbar .ql-formats .ql-image {
  display: none !important;
}
.fix-confirmation-popup {
  .p-confirm-dialog-message {
    margin-left: 0px !important;
  }
  .fix-error-note {
    padding: 12px;
    background-color: #fff5f2;
    border-radius: 8px;
  }
}
.file-selector-container {
  display: inline-block;
  box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24);
  border-radius: 10px;
  width: 100%;
  cursor: pointer;

  .file-selector-button {
    font-weight: bold;
    padding: 0.5em;
    background-color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24);
    border-radius: 10px;
    cursor: pointer;
  }

  input::file-selector-button {
    font-weight: bold;
    color: grey;
    padding: 0.5em;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
  }
}

.add-event-icon-picker {
  max-width: 300px;
  .p-dropdown-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.attachment-input {
  background-color: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
//   display: flex;
// }
.attendees-table {
  table-layout: fixed;

  .p-avatar-circle {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
  td {
    vertical-align: middle;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    word-break: break-all;
  }

  .attendee-names {
    width: 40%;
  }
}
.add-attendees {
  .p-multiselect-label.p-multiselect-items-label {
    padding: 0.25rem 0.5rem !important;
  }
}
.case-notes-details {
  max-height: 200px !important;
  overflow-y: auto;
  background: #fafbfe !important;
  border-radius: 16px !important;
  box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
  border-radius: 12px !important;
  border: 1px solid #dee2e6;
}
.case-note-area {
  max-height: 360px !important;
  min-height: 200px !important;
  overflow-y: auto !important;
}
.case-note-text {
  white-space: break-spaces;
  overflow-y: auto;
  max-height: 360px;
  min-height: 200px !important;
  font-size: 14px;
  font-family: 'AvenirNextR';
}
.freq-day-dropdown {
  max-width: 285px !important;
  min-width: 285px !important;
  width: 250px !important;

  .p-dropdown-items {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .p-dropdown-item {
    width: 33px !important;
    height: 33px !important;
    text-align: center;
  }
  .p-dropdown-items-wrapper {
    overflow-y: auto !important;
  }
  .p-dropdown-items li,
  .p-multiselect-panel .p-dropdown-items li {
    margin: 5px 5px !important;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    min-width: 30px !important;
    width: 30px !important;
    text-align: center !important;
  }

  .p-dropdown-items-wrapper {
    overflow-x: hidden !important;
  }
}
.team-mgmt-name {
  width: 18%;
}
.team-mgmt-action {
  width: 17%;
}
.rfipdropdown--bluegrey .rfipicons__ibox:hover {
  background-color: #deecf3 !important;
}
.address-card {
  .p-card-body {
    padding-top: 0px !important;
    padding-bottom: 5px !important;
  }
  .p-card-content {
    padding-top: 10px !important;
    padding-bottom: 5px !important;
  }
}
.lead-desc {
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 65px;
}
.lead-tags {
  max-height: 200px;
  overflow-y: auto;
  .p-chips-multiple-container {
    overflow-y: auto;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    max-width: 100%;
  }
  .p-chips-token-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.lead-summary {
  .tag-highlight {
    margin-right: 6px !important;
    margin-bottom: 6px !important;
  }
}
.truncate-3-line {
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  max-height: 150px;
}
.text-box-L {
  max-height: 360px;
  overflow-y: auto;
}
.my-dialog.custom-dialog {
  width: 99vw !important;
  height: 99vh !important;
}

.carousel .slider-wrapper {
  height: 73vh;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  height: 100%;
}

.carousel img {
  height: 100%;
  object-fit: contain;
}

.slider-wrapper .slider .slide {
  height: 100%;
  width: 100%;
}

.carousel.carousel-slider {
  display: flex;

  .carousel .thumb {
    opacity: 0.5;
  }
}
.carousel-slider {
  .image-comp {
    height: 100%;
    width: 100%;
    margin-right: 3rem;
    margin-left: 3rem;
    @media screen and (max-width: 1200px) {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  .pi-chevron-left {
    margin-right: 12px !important;
  }
  .pi-chevron-right {
    margin-left: 12px !important;
  }
  .slider-wrapper {
    img {
      width: auto;
      border-radius: 16px;
      max-width: 100%;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
      }
    }
    .slide {
      .icon-save::before,
      .icon-Print::before,
      .icon-zoom-in::before,
      .icon-zoom-out::before {
        font-size: 24px;
      }
      .icon-signature::before {
        font-size: 22px;
      }
    }
  }
  .carousel-status {
    color: $black-800;
    text-shadow: none;
    font-size: 12px;
  }
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  opacity: 1;
  border: none !important;
}

// .carousel .slide iframe {
//   margin: 0 !important;
// }
.carousel .thumbs {
  text-align: center !important;
}

.thumbs-wrapper.axis-vertical button {
  background-color: #6f7275;
  border: none;
  border-radius: 8px;
  padding: 8px;
  // margin: 5px;
  cursor: pointer;
  outline: none;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbs-wrapper.axis-vertical button:hover {
  background-color: #444546;
}

.react-transform-wrapper {
  height: 100% !important;
  width: 100% !important;
}

.react-transform-component {
  height: 100% !important;
  width: 100% !important;
  .contact-name-input {
    border: none !important;
    border-radius: 12px !important;
    .p-inputwrapper-focus {
      box-shadow: none !important;
    }
    .p-inputwrapper {
      border: none !important;

      &:focus {
        border: none !important;
      }
    }
    .p-inputtext {
      border: 1px solid #f0f2f5 !important;
      box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
      border-radius: 12px !important;
      min-width: 100% !important;
      &:focus {
        border: none !important;
      }
      .p-inputtext:focus {
        box-shadow: #fff !important;
      }
    }
  }
  .payment-tax-input {
    height: 39px;
  }
}
.table-checkbox {
  display: block;
}
.carousel .thumb {
  margin-right: 0px;
}
.zoom-controls {
  position: absolute;
  top: -15px;
  right: 10px;
  z-index: 9999;
}
.preview-dialog {
  .p-dialog-header {
    padding: 15px 1rem !important;
  }
  .p-dialog-content {
    overflow-y: hidden;
  }
  .carousel .thumbs-wrapper {
    margin: 10px;
  }
  .p-dialog-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .PSPDFKit-div {
    height: calc(100vh - 190px) !important;
  }
}
.p-dialog.preview-dialog {
  max-height: 98% !important;
}
.subscription-plans {
  .p-checkbox {
    cursor: inherit;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background-color: #2196f3;
  }
}
.trello-height {
  height: calc(100vh - 255px) !important;
}
.subscription-plan {
  .p-checkbox .p-checkbox-box {
    cursor: default;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background-color: #2196f3;
  }
}

.text-decoration-none {
  text-decoration: none;
}
.rate-input {
  input,
  .input-shadow .p-inputtext {
    border: none !important;
    padding: 0px !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.future-payment-table {
  position: relative;

  @media screen and (max-width: 991px) {
    margin-top: 35px;
  }
}
.next-prev-buttons {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #fafbfe;
  @media screen and (max-width: 991px) {
    top: -33px;
  }
}
.next-prev-icons {
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #517dda;
  cursor: pointer;
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card-main {
  background: linear-gradient(90deg, #405073 0%, #496298 40%, #556c99 100%);
  color: #fff;
  min-height: 160px;
  max-height: 200px !important;
  max-width: 285px;
  min-width: 265px;
}

.redirection-link {
  @extend .text-primary-dark;
  cursor: pointer !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.redirection-link-no-text-transform {
  @extend .text-primary-dark;
  cursor: pointer !important;
  text-decoration: none !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.p-calendar-w-btn .p-inputtext {
  height: 40px !important;
}

td {
  position: relative;
}

tr.strikethrough td {
  text-decoration: line-through;
}
// tr.strikethrough td:before {
//   content: ' ';
//   position: absolute;
//   top: 50%;
//   left: 0;
//   border-bottom: 1px solid #111;
//   width: 100%;
// }

// tr.strikethrough td:after {
//   content: '\00B7';
//   font-size: 1px;
// }
.billing-wrap .filter-calender {
  input {
    width: 100% !important;
  }
}
#practice_area {
  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    -moz-appearance: textfield;
    appearance: textfield;
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.resp-card {
  @media screen and (max-width: 991px) {
    width: auto !important;
  }
}
.p-inputnumber {
  input {
    width: 100%;
  }
}
.task-border {
  border-bottom: 1px solid #f0f2f5;
}
.p-datatable .p-datatable-thead > tr > th {
  border-bottom: 1px solid #afb8c0 !important;
}
.files-buttons {
  .icon-save:before,
  .icon-delete:before {
    color: #fff !important;
  }
}
.fc-dayGridMonth-view {
  .fc-daygrid-day-number {
    color: #7f8f9f;
  }
}
.fc-multiMonthYear-view {
  .fc-daygrid-day-number {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: center;
    color: #171e27;
  }
  .fc-col-header-cell-cushion {
    text-transform: none !important;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #333c47 !important;
  }
  .fc-daygrid-day-top {
    justify-content: center;
  }
  .fc-daygrid-day-bottom {
    justify-content: center;
    display: flex;
  }
  .fc-daygrid-more-link {
    align-items: center;
    display: flex !important;
    justify-content: center;
  }
  .fc-col-header-cell {
    .fc-scrollgrid-sync-inner {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }
  }
  .fc-daygrid-day {
    height: 45px !important;
  }
}
.fc .fc-multimonth-multicol .fc-multimonth-month {
  padding: 0px 3em 1.2em !important;
}
.case-overview {
  .p-datatable .p-datatable-thead > tr > th {
    padding-top: 0px !important;
  }
}
.video-download {
  position: absolute;
  z-index: 999;
  right: 0px;
}

.signature-modal {
  max-height: 98% !important;
  .PSPDFKit-div {
    height: calc(100% - 0px) !important;
  }

  .PSPDFKit-Container {
    height: 100% !important;
  }
  .p-dialog-content {
    padding: 0 20px !important;
  }
}
.advanced-search {
  margin-bottom: 1rem;
  .head {
    margin-bottom: 15px;
    .italic {
      font-style: italic;
    }
    .head-sub-title {
      color: #677a8e;
      font-weight: 700;
    }
  }
  .search-result {
    .p-card-content {
      padding: 0px;
    }
    .card-title {
      font-size: 14px;
      font-weight: 700;
      color: #5a6672;
    }
    .content {
      color: #2b5eca;
      font-weight: 700;
      font-size: 14px;
      padding: 5px;
      cursor: pointer;
    }
    ul.custom-bullet {
      list-style: none; /* Remove default bullet points */
    }

    ul.custom-bullet li::before {
      content: '\2022'; /* Unicode character for bullet point */
      color: #d9d9d9; /* Change the color of the bullet points */
      display: inline-block;
      width: 1.5em; /* Adjust spacing between bullet point and list item text */
      margin-left: -1em; /* Adjust spacing between bullet point and list item text */
    }

    .custom-list {
      list-style: none; /* Remove default bullet points */
      display: flex; /* Use flexbox layout */
      flex-wrap: wrap; /* Allow list items to wrap to the next line */
      // min-height: 80px;
      max-height: 250px; /* Fixed height for the ul */
      overflow-y: auto;
    }

    .custom-list li {
      flex: 0 0 calc(33.33% - 10px);
      padding: 5px;
    }
  }
}
.entities-container {
  ul.custom-bullet {
    list-style: none;
  }

  ul.custom-bullet li::before {
    content: '\2022';
    color: #d9d9d9;
    display: inline-block;
    width: 1.5em;
    margin-left: -1em;
  }

  .custom-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    max-height: 250px;
    overflow-y: auto;
  }

  .custom-list li {
    flex: 0 0 calc(33.33% - 10px);
    padding: 5px;
  }
  .card-title {
    font-size: 14px;
    font-weight: 700;
    color: #5a6672;
  }
  .entities-card {
    .p-card-body {
      padding-top: 0px;
    }
  }
}
.no-entity-card {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.create-msg-popup,
.invoice-popup {
  .p-inputwrapper {
    width: 100% !important;
  }
  .p-inputtext {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.FM-filter,
.message-wrap,
.calendar-filter,
.task-filter-wrap,
.time-entries-filter,
.create-msg-popup {
  @media screen and (max-width: 992px) {
    .p-autocomplete-input {
      border: none;
      width: 100% !important;
      max-width: 100% !important;
      border-radius: 12px;
    }
    .p-autocomplete {
      margin: 8px 0 !important;
      width: 100%;
    }
  }
  .p-inputtext,
  .p-inputtext:hover {
    box-shadow: none !important;
  }
  .p-autocomplete {
    border-radius: 12px !important;
    border: 1px solid #f0f2f5 !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    background: #fff;
    font-family: 'AvenirNextR' !important;
    font-size: 14px !important;
    box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24);
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }

    .p-autocomplete-input {
      border: none;
      width: 190px;
      max-width: 190px;
      border-radius: 12px;
    }

    .p-autocomplete-dropdown {
      background: #fff;
      border: none;
      border-radius: 12px;

      &:hover {
        background: #fff !important;
      }
    }
  }
}
.gap-8 {
  gap: 8px;
}
.gap-10 {
  gap: 10px;
}
.gap-50 {
  gap: 50px;
}
.flex-1 {
  flex: 1;
}
.p-multiselect-panel.p-component.p-connected-overlay-enter-done {
  z-index: 350000 !important;
}
.merge-fields {
  .p-calendar-w-btn .p-datepicker-trigger {
    background-color: #fff;
    border: none;
    border-radius: 12px;
  }
  .p-inputtext:enabled:focus,
  .p-inputtext:enabled:hover {
    border: none;
  }
}

.archived-tags {
  margin-left: 30px;
  span {
    font-size: 11px !important;
  }
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-251ych-control {
  min-width: 150px !important;
  height: 40px !important;
}
.css-b62m3t-container {
  border: 1px solid #f0f2f5 !important;
}
.report-totals {
  box-shadow: 0px 1px 3px rgba(103, 122, 142, 0.24) !important;
  border-radius: 12px !important;
  padding: 1rem !important;
  background-color: #f8f9fa;
  margin-bottom: 5px;

  .totals-section {
    gap: 80px;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      gap: 15px;
    }
    @media screen and (max-width: 1250px) {
      gap: 20px;
    }
  }
  .p-card-body {
    padding: 0px !important;

    .p-card-content {
      padding: 0px !important;

      p {
        margin: 0px;
      }
    }
  }
}

.report-filters-wrap {
  .filter-wraper {
    min-width: 155px;
    .p-dropdown-label {
      width: auto !important;
    }
    .p-multiselect.p-multiselect-clearable .p-multiselect-label {
      padding-right: 0px;
      margin-right: 20px;
    }
    .p-multiselect-token {
      max-width: 100%;
    }
    .p-multiselect-token-label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.time-spend {
  .progress-bar {
    display: flex;
    height: 4px; /* Adjust the height as needed */
    background-color: #f1f1f1;
    border-radius: 4px;
    overflow: hidden;
    flex-direction: row;
  }

  .progress {
    height: 100%;
  }
}

.completed-bg {
  background: linear-gradient(to right, #55a6e1, #6da6fd); /* Gradient from blue to lighter blue */
}

.remaining-bg {
  background: linear-gradient(to right, #f4b264, #f8e4a7); /* Gradient from orange to lighter orange */
}

.dot-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  display: inline-block;
}
.drag-drop-box {
  background: #f3f5fb;
  min-height: 80px;
  height: auto;
  padding: 12px;
  gap: 20px;
  border-radius: 12px;
  border: 1px dashed #929caa;

  .existing-files {
    max-width: 100%;
    height: 40px;
    align-items: center;
  }
}
.future-payment-total {
  .p-card-header {
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #5a6672;
    margin-bottom: 1rem;
    font-family: 'AvenirNextD';
  }
}
.scroll-container::-webkit-scrollbar {
  width: 2px; /* Set width of the scrollbar */
}
.dynamic-header-section {
  white-space: nowrap;
  text-align: center;
}
.monthly-total-wrap {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.group-ul-list {
  padding-left: 17px !important;
  list-style-type: disc !important;
}

.template-builder-component {
  .label-container {
    background-color: lightgrey;
    // overflow: hidden;
    white-space: nowrap;
    // text-overflow: "ellipsis";
    max-width: 100%;
  }

  .condition-label-container {
    background-color: lightgrey;
    white-space: nowrap;
    max-width: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 0px;
    display: none; /* make scrollbar transparent */
  }
}
.mail-threads > div:last-child {
  border-bottom: none !important;
}
.create-msg-popup,
.email-quill-reply {
  .quill {
    background: #fff;
    box-shadow: 0 1px 3px rgba(103, 122, 142, 0.24);
    overflow: hidden;
    border-radius: 12px;
  }
  .quill {
    overflow: visible;
    height: auto !important;

    .ql-editor {
      min-height: 150px;
    }
  }
  .ql-container.ql-snow {
    border-radius: 0 0 12px 12px !important;
    min-height: 100px;
    height: calc(100% - 42px);
  }
  .ql-toolbar.ql-snow {
    border-radius: 12px 12px 0 0 !important;
    background: #f8f9fa;
  }
}
.message-create-form {
  .p-multiselect-token {
    max-width: 100%;
  }
  .p-multiselect-token-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.suggested-times-container {
  background-color: #fff;
  padding: 0px 5px !important;
  width: 230px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: #000;
  display: inline-block;
  margin: 0 !important;
  max-height: 260px;
  overflow: auto;

  .date-group {
    margin-bottom: 12px;
    padding: 0px !important;

    &:last-child {
      margin-bottom: 0;
    }

    .date-header {
      font-weight: bold;
      margin-bottom: 0;
      padding: 0;
      color: #333;
    }

    .time-slots {
      display: flex;
      flex-direction: row;
      gap: 8px;
      flex-wrap: wrap;
      padding: 0px !important;
      margin-top: 0;

      .time-slot {
        padding: 4px 8px !important;
        border-radius: 4px;
        background-color: #f0f2f5;
        text-align: center;
        // border: 1px solid #e0e0e0;
        cursor: pointer;

        &:hover {
          background-color: #e6e6e6;
        }
      }
    }
  }
}

.template-builder-component {
  .label-container {
    background-color: lightgrey;
    // overflow: hidden;
    white-space: nowrap;
    // text-overflow: "ellipsis";
    max-width: 100%;
  }

  .condition-label-container {
    background-color: lightgrey;
    white-space: nowrap;
    max-width: 100%;
  }
  .scroll::-webkit-scrollbar {
    width: 0px;
    display: none; /* make scrollbar transparent */
  }
}
.card-bg-color {
  background-color: #fafbfe;
}

.deactivate-account {
  background-color: #878787 !important;
  border: 1px solid #878787 !important;
}
.send-review-btn {
  height: 40px;
}
.message-subject {
  @media screen and (min-width: 1021px) {
    width: 23% !important;
  }
}
.truncate-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 1.5;
  max-height: 3em;
}
.overdue-tag {
  background-color: #ff583e !important;
  color: #fff !important;
}
.draft-tag {
  color: #ffffff !important;
  background-color: #7f8f9f !important;
}
.select-tag-input {
  .p-multiselect-token {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .p-multiselect-token-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
.files-note {
  background: #d3eff8;
  padding: 8px 12px;
  width: fit-content;
  border-radius: 12px;
}
.file-viewer {
  height: calc(100% - 50px) !important;
}

//-------------------- Forms CSS Start -------------------------
.sidebar-sticky {
  top: 0;
  position: sticky;
  height: 100%;
  .content {
    height: calc(100vh - 47px);
    overflow: auto;
  }
}

.forms,
.field-settings {
  .text-expire {
    color: #da4451;
    background-color: #ffeaea;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 600;
  }
  .primary-color {
    color: #3853bd !important;
  }

  .condition-tag {
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 2px 6px;
  }

  .accordian-header-custom {
    a {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      color: #3853bd !important;

      padding: 0 !important;
      margin-bottom: 10px;
      background: none !important;
      border: none !important;

      span {
        margin-right: 0 !important;
        color: #3853bd !important;
      }
    }
  }

  .accordian-content-custom {
    .p-accordion-content {
      border: none !important;
      padding: 0 !important;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .bi-plus-lg {
      color: #616161;
      font-size: 15px;
      -webkit-text-stroke: 0.2px;
    }
  }

  .alignment-settings {
    .p-selectbutton .p-highlight {
      background-color: #4266ce;
      .pi {
        color: #fff;
      }
      &:hover {
        background-color: #4266ce;
        border-color: #4266ce;
      }
    }
  }
}

.row-custom {
  position: relative;
  transition: transform 0.25s ease, background-color 0.3s ease;
}

.relations-accordion {
  .p-accordion-header-link {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 12px 10px !important;
    .p-accordion-toggle-icon {
      margin-right: 0 !important;
    }
  }
}

.dragging-custom {
  transform: scale(1.05);
  background-color: #ffff;
  border-radius: 10px;
}

.highlight-custom {
  background-color: #e6f7ff;
  animation: highlightFade 0.5s forwards;
  .sub-field-highlight {
    border-color: #a2c2ee;
  }
}
@keyframes highlightFade {
  0% {
    background-color: #e6f7ff;
  }
  100% {
    background-color: transparent;
  }
}
.error-tab a {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.a4-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 210mm;
  margin: 20px auto;
  padding: 40px;
  background-color: #fff;
  overflow: visible;
  position: relative;
  height: 100%;
  // gap: 24px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  .form-desc {
    margin-bottom: 10px;
    padding: 1rem 0.5rem 0.25rem 0.5rem;
    font-size: 16px;
  }

  .help-text {
    margin-top: 6px;
    font-weight: 400;
    line-height: 18px;
    color: #616161;
    cursor: pointer;
  }

  .add-option {
    margin-top: 6px;
    font-weight: 400;
    line-height: 18px;
    color: #616161;
    cursor: pointer;
    margin-left: auto;
  }

  .help-text-label {
    font-size: 12px;
    margin-top: 4px;
    font-weight: 400;
    line-height: 18px;
    color: #9e9e9e;
  }
}
.a4-width {
  max-width: 190mm !important;
}

@media screen and (min-width: 1150px) {
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
  .p-multiselect-panel .p-dropdown-items .p-dropdown-item {
    max-width: auto !important;
  }
  .sentiment-column {
    width: 150px;
  }
}
@media (max-width: 768px) {
  .a4-page {
    width: 90%; /* Adjust width for smaller screens */
    padding: 10px; /* Reduce padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .a4-page {
    width: 100%;
    padding: 5px;
  }
}

.selectable-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.2s;
  padding: 16px;
  border: 2px solid transparent;

  &.selected {
    border-color: #a2c2ee;
  }

  &.dragging-custom {
    box-shadow: 0 0 0 2px #a2c2ee;
    outline: 4px solid transparent;
    outline-offset: 4px;
    padding: 16px;
  }

  &.selected,
  &.sub-field-highlight {
    padding: 16px;
    .icon-group {
      display: flex;
      gap: 0.5rem;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      .sm-button {
        background-color: #ffffff !important;
        border-radius: 8px !important;
        border: 1px solid #e0e0e0 !important;
      }
    }

    .input-group {
      display: flex;
      gap: 1rem;
      width: 100%;
    }
  }
}

.p-sidebar-left-forms {
  width: 25rem !important;
  border-radius: 1px !important;
  .p-sidebar-header {
    padding: 0 !important;
  }
  .p-sidebar-content {
    height: 100% !important;
  }
}

/* Hover effects for buttons inside SelectableWrapper */
.selectable-button {
  transition: transform 0.2s ease-in-out, background-color 0.2s ease;
}

.selectable-button:hover {
  transform: scale(1.1);
  color: white;
}

.icons-add-custom {
  transition: transform 0.4s ease-in-out;
}

.icons-add-custom:hover {
  transform: scale(1.15);
}

.icons-add-custom:active {
  transform: scale(0.8);
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.appear-animation {
  animation: appear 0.2s ease-out forwards;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

//-------------------- Forms CSS End -------------------------

.fa-user-tie {
  color: #7f8f9f !important;
  font-size: 12px;
  padding-left: 6px;
}
.text-message-wrap {
  .p-tabview-nav {
    width: 100%;
    border: none !important;
  }
  .p-tabview-nav-content {
    border-radius: 16px 16px 0px 0px;
  }
  .p-unselectable-text {
    width: 50%;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #eff4ff !important;
    border: none !important;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none !important;
  }
  .p-tabview-panels {
    display: none;
  }
  .p-tabview-nav-link {
    width: 100%;
    text-align: center;

    .p-tabview-title {
      width: 100%;
    }
  }
}

.text-white {
  color: #fff !important;
}

.form-builder,
.field-settings {
  .help-text {
    margin-top: 6px;
    font-weight: 400;
    line-height: 18px;
    color: #616161;
    cursor: pointer;
  }
  .template-head {
    color: #212121;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
  }
  .field-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #616161;
  }
  .add-first-label {
    background-color: #fafafa;
    border-radius: 8px;
    border: 1px dashed #e0e0e0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #212121;
    padding: 24px;

    .add-field-icon {
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border: 1px solid var(--color-Gray-200, hsl(216, 20%, 95%));
      background-color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 16px;

      .icon-add {
        font-size: 15px !important;
        color: #616161;
      }
    }
    .fa.bi {
      margin-top: 1px !important;
    }
  }
  .p-inputswitch .p-inputswitch-slider:before {
    width: 16px;
    height: 16px;
    left: 0.22rem;
    margin-top: -0.515rem;
  }
  .p-inputswitch {
    height: 20px;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #4266ce !important;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background-color: #fff !important;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(0.9rem) !important;
  }
  .p-inputswitch-slider {
    height: 20px;
    width: 36px;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: #3853bd;
    border-color: #3853bd;
  }
  .input-shadow.p-inputtext,
  .p-inputtext.p-dropdown,
  .p-inputtext.p-autocomplete-panel {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    border: 1px solid #f0f2f5 !important;
  }

  .p-medium {
    color: #424242 !important;
    margin-bottom: 6px;
  }

  .pi-cog,
  .pi-copy,
  .pi-sitemap {
    color: #212121;
  }

  .pi-trash {
    color: #dc3545;
  }
}

.form-header {
  background-color: #fff;
  border-bottom: 1px solid #f0f2f5;

  .form-primary-btn {
    padding: 10px 16px;
    gap: 8px;
    border-radius: 8px;
    border: 1px 0px 0px 0px;
    background-color: #517dda;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #517dda;
  }
  .form-sec-btn {
    padding: 10px 16px;
    gap: 8px;
    border-radius: 8px;
    border: 1px 0px 0px 0px;
    background-color: #fff;
    color: #616161;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #e0e0e0;
  }
}
.field-settings {
  padding: 24px !important;
  background-color: #fff !important;

  .p-sidebar-content {
    padding: 0px !important;
  }
  .field-settings-head {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #231f20;
  }

  .pi-times {
    color: #979798 !important;
  }
}
.file-sharing-details {
  .shared-card-container {
    width: 220px;
  }
  .shared-card {
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
  }

  .shared-with-client {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .shared-icon {
    color: #2fa84f;
    font-size: 15px;
    margin-right: 8px;
  }

  .shared-text {
    color: $black-600;
    font-weight: bold;
    font-size: 14px;
  }

  .grey-section {
    background: #f0f2f5;
    border-radius: 8px;
    padding: 15px 20px;
    text-align: center;
  }

  .title-section {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0.5rem;
    color: $black-600;
  }

  .downloads-views-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #6c757d;
  }

  .info {
    text-align: center;
  }

  .label {
    font-size: 14px;
    color: #6c757d;
  }

  .value {
    font-weight: bold;
    color: #212529;
    font-size: 16px;
  }

  .logo-wrap {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .logo-shared-files {
    width: 88px;
    height: 18px;
  }

  .version {
    margin-left: 1rem;
    font-weight: bold;
    color: #fff;
  }

  /* Main Content */
  .shared-files-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    .icon-save:before {
      color: #212529;
    }
    .p-card {
      background-color: #f0f2f5;
      padding: 24px;
    }
  }

  .shared-files-card {
    width: 50%;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .p-card-body {
      padding: 0px;
    }
  }

  /* Header Content in Card */
  .header-title {
    margin: 0;
    color: #333c47;
    font-family: AvenirNextD;
    font-style: normal;
    font-size: 24px;
    letter-spacing: 0.0025em;
    line-height: 40px;
  }

  .header-subtitle {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    color: #555;
  }

  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 14px;
    margin-bottom: 0.5rem;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .file-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  // .file-icon {
  //   font-size: 1.5rem;
  // }

  .file-name {
    font-size: 14px;
    color: #495057;
    font-family: AvenirNextR;
  }

  .version-shared-file {
    margin-left: 1rem;
    font-weight: bold;
    color: #060505;
  }

  .download-button {
    cursor: pointer;
  }

  .support-link {
    text-decoration: none;
    font-weight: bold;
  }
}
.file-share-tooltip .p-tooltip-text {
  padding: 0px !important;
}
.file-share-tooltip {
  .p-card-content {
    padding: 0px !important;
  }
}

.relations {
  .no-relations {
    background: #f8f9fa;
    font-weight: 700;
    font-size: 16px;
    border: 1px dashed #cecece;
    border-radius: 6px;
  }
}

.file-sharedlist-table {
  background-color: #fff;
  padding: 16px;
  box-shadow: 0px 2px 6px rgba(103, 122, 142, 0.24);
  border-radius: 16px;

  .text-expire {
    color: #da4451;
    background-color: #ffeaea;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 600;
  }
  .p-button:enabled:hover {
    background: none !important;
  }
}
.fileManager-actions {
  .fa-link {
    -webkit-text-stroke: 0.6px #fff !important;
    position: relative;
  }
  .link-copied-msg {
    position: absolute;
    background-color: #fff;
    padding: 5px 10px;
    box-shadow: 0px 2px 6px rgba(103, 122, 142, 0.24);
    z-index: 999;
    border-radius: 8px;
    top: -35px;
    right: -30px;
    white-space: nowrap;
    width: 100px;
  }
}
/* Container */
.shared-files-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

/* Header */
.shared-files-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}
.shared-files-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d9d9d9;
  padding: 12px 24px;
  font-size: 13px;
  color: #475564;
  .support-link {
    color: #475564;
  }
}

.rating-button {
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #007bff;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #d4e6fb;
  }
}

.rating-button.active {
  border-color: #517dda;
  background-color: #517dda;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .sm-gap-3 {
    gap: 3px !important;
  }
  .rating-button {
    padding: 10px;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icon-NPS::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../images/nps.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.prev-goals {
  @media screen and (max-width: 900px) {
    .truncate-2 {
      display: inline;
    }
  }
}

.badge-button-wrapper-btn {
  background: #ff583e;
  right: -4px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  color: white !important;
  font-size: 12px;
  margin: 0;
  line-height: initial;
}

.side-nav a.current-nav {
  .icon-NPS {
    filter: invert(41%) sepia(52%) saturate(597%) hue-rotate(179deg) brightness(96%) contrast(85%);
  }
}
.bi-code-slash,
.bi-link-45deg,
.bi-copy,
.bi-envelope,
.bi-trash3 {
  color: #9e9e9e;
  font-size: 16px;
}
.tooltip-intake {
  .bi-link-45deg {
    font-size: 18px !important;
  }
}
.intake-dates {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.online-lead-modal {
  .p-dialog-content {
    padding: 0 1rem 1rem 1rem !important;
  }
  .p-dialog-header {
    padding: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

.intake-form-table {
  .p-tabmenu .p-tabmenu-nav {
    border: 1px solid #f0f2f5;
    border-width: 1px;
  }
  .p-datatable-thead {
    border-color: #f0f2f5;
  }
  .p-datatable .p-datatable-thead > tr > th {
    border-bottom: 1px solid #f0f2f5 !important;
  }
  .p-tabmenu {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  tr td:first-child {
    padding-left: 24px !important;
  }
  tr th:first-child {
    padding-left: 24px !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none;
  }
  .p-highlight .p-menuitem-link {
    background-color: #f1f5fd !important;
    border-bottom: 2px solid #007bff !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    margin: 0px !important;
    border: none;
  }
  .p-tabmenu {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .p-row-toggler:focus {
    box-shadow: none !important;
  }
  .sub-title {
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #5a6672;
    margin-bottom: 1rem;
    font-family: 'AvenirNextD';
    border-bottom: 1px solid #e7e9ec;
    padding-bottom: 12px;
  }
}
.dynamic-labels-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 25px;
}

.label-value-pair {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1 1 calc(50% - 16px);
}

@media (min-width: 768px) {
  .label-value-pair {
    flex: 1 1 calc(33.33% - 16px);
  }
}

@media (min-width: 1200px) {
  .label-value-pair {
    flex: 1 1 calc(25% - 16px);
  }
}

.dynamic-labels-container::after {
  content: '';
  flex: 1 1 calc(25% - 16px);
  visibility: hidden;
}

.label-fields {
  font-weight: bold;
  color: #333;
}

.value-fields {
  color: #555;
}

.conditions-sidebar {
  width: 580px !important;
  padding: 0px !important;

  .p-sidebar-content {
    padding: 0px !important;
  }
  .border-roundeded {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  .condition-label {
    text-transform: uppercase;
    background-color: #ebf3ff;
    color: #3853bd;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: 700;
  }
}

.condition-bar-bottom {
  position: sticky;
  bottom: 0px;
  height: 320px;
  background-color: rgb(255, 255, 255);
  margin: 0 -22px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  z-index: 1;
  border-radius: 7px;
}

.custom-dropdown-wrapper {
  display: inline-block;
  .custom-dropdown {
    width: 80px; /* Adjust width as per your requirement */
    border: 1px solid #c8d9f5;
    border-radius: 7px !important;
    background-color: #f1f5fd;
    .p-dropdown-label {
      font-size: 14px;
      color: #2c50b8 !important;
      font-weight: bold;
    }
    .p-dropdown-trigger {
      color: #2c50b8 !important;
      width: 1.8rem !important;
    }
    .p-dropdown-panel {
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
  .p-inputtext {
    padding: 0.1rem 0.5rem 0 !important;
  }
}

.advanced-search-bar {
  .overlay {
    padding: 4px 2px;
    position: absolute;
    right: 15px;
    top: 7px;
    z-index: 99;
  }
}

.practice-area-section {
  .pa-tags {
    background-color: #fff !important;
    margin-right: 6px;
    span {
      color: #616161;
    }
  }
  .no-pa {
    color: #616161;
  }
}

.indicator-online-lead {
  background: #ff583e;
  right: -9px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  color: white !important;
  font-size: 12px;
}

.truncate-3 {
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 100px;
}

.pa-tooltip {
  width: fit-content;
  span {
    white-space: nowrap;
    display: inline-block;
    text-transform: capitalize;
  }
}
.more-tag {
  span {
    color: #2655b9 !important;
    cursor: pointer;
  }
}
.more-tag-button {
  background: #f0f2f5 !important;
  border-radius: 8px !important;
  padding: 4px 8px !important;
  border: none !important;
  span {
    color: #2655b9 !important;
    cursor: pointer;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
}
.lead-gear {
  border: 1px solid #7f8f9f !important;
  i {
    font-size: 20px;
  }
}
.report-accordion {
  width: 100%;
  .p-accordion-content {
    border: none !important;
    padding: 10px 0px !important;
  }
  .p-accordion-header-link {
    width: 100%;
    padding-right: 8px !important;
  }
  .accordian-header-custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }
  .p-accordion-header-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #677a8e;
    text-decoration: none !important;
    display: flex;
    align-items: center;
  }
  .accordian-header-custom .p-accordion-header-text {
    margin-right: auto;
    text-align: left;
  }

  .accordian-header-custom .p-accordion-toggle-icon {
    margin-left: auto;
  }
  .accordian-header-custom .p-accordion-toggle-icon {
    order: 2;
    margin-right: 0px !important;
  }

  .accordian-header-custom .p-accordion-header-text {
    order: 1;
  }
  .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  .p-accordion-tab:last-child .p-accordion-header .p-accordion-header-link {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  .p-accordion-tab .p-accordion-header-link {
    border-radius: 0;
    background: #fafbfe;
    border: none !important;
  }
  .p-accordion-toggle-icon.pi {
    font-size: 12px;
  }
  .p-accordion-header-link[aria-expanded='true'] .p-accordion-header-text {
    color: #517dda;
    font-weight: 600;
  }
  .p-accordion-header-link[aria-expanded='true'] .p-accordion-toggle-icon.pi {
    color: #517dda;
  }
  .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
  }
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #f0f2f5;
  }
}
.template-tabs {
  .p-tabmenu .p-tabmenu-nav {
    position: relative;

    .p-tabmenuitem:nth-child(3) {
      .p-menuitem-link {
        padding: 0px !important;
        border: none !important;
      }
      position: absolute;
      top: 15px;
      right: 10px;
    }
  }
}
.status-tags {
  .p-tag {
    width: fit-content !important;
  }
}
.template-tags {
  border-radius: 6px;
  background-color: #f0f5fc;
  border: 1px solid #f0f5fc;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #4266ce;
  font-weight: 500;
}
.template-header {
  .btn-sec,
  .btn-sec:hover {
    padding: 10px 16px 10px 16px;
    gap: 8px;
    color: #616161 !important;
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    border-color: #e0e0e0 !important;
    background-color: #fff !important;
  }
  .btn-primary,
  .btn-primary:hover {
    padding: 10px 16px 10px 16px;
    gap: 8px;
    color: #fff !important;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #517dda;
    border-radius: 8px;
    border-color: #517dda !important;
    background-color: #517dda !important;
  }
  .btn-primary:active {
    background: #517dda !important;
    color: #fff !important;
    border-color: #517dda !important;
  }
  .btn-sec:active {
    background: #fff !important;
    color: #616161 !important;
    border-color: #e0e0e0 !important;
  }
}
.template-report {
  .template-scroll > div:first-child {
    overflow: auto !important;
  }
}
.text-message-tab-color {
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #ffffff !important;
    border: none !important;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link {
    background-color: #eff4ff !important;
    border: none !important;
  }
}
.case-report-group {
  // max-width: min-content;
  max-height: 300px;
  overflow-y: auto;
  // .attorney-name {
  //   a {
  //     width: 100%;
  //   }
  // }
}

@media (min-width: 990px) {
  .author-width {
    width: 200px;
  }
  .billingCode-width {
    width: 500px !important;
  }
  .groups-width {
    width: 110px;
  }
  .ellipsis-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

@media (min-width: 990px) {
  .intake-forms-ellipsis-text {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 250px !important
    ;
  }
}

@media (min-width: 990px) {
  .tag-ellipsis-text {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #171e27 !important;
    max-width: fit-content !important;
  }
}

@media (min-width: 990px) {
  .case-list-tag-ellipsis-text {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #48515b !important;
    max-width: fit-content !important;
  }
}

@media (min-width: 990px) {
  .case-d-tag {
    background-color: $black-100 !important;
    border-radius: 8px !important;

    .p-tag-value {
      font-weight: 500 !important;
    }
  }
}

@media (min-width: 990px) {
  .case-date-ellipsis-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 150px !important;
  }
}

@media (min-width: 990px) {
  .team-management-ellipsis-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 80px !important;
  }
}

@media (min-width: 990px) {
  .file-manager-ellipsis-text {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #48515b !important;
  }
}

.message-ellipsis-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 200px !important;
}

@media (max-width: 1170px) {
  .message-ellipsis-text {
    width: 50px !important; /* Change width to 50px for smaller screens */
  }
}

@media (min-width: 990px) {
  .client-ellipsis-text {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: fit-content !important;
  }
}

@media (min-width: 990px) {
  .message-date-ellipsis-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 120px !important;
  }
}

@media (min-width: 990px) and (max-width: 1500px) {
  .message-date-ellipsis-text {
    width: 80px !important;
  }
}

@media (min-width: 990px) {
  .message-author-ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
}

@media (min-width: 990px) {
  .activity-log-ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
}

@media (min-width: 990px) {
  .activity-log-users-ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
  }
}

.payment-card {
  background: linear-gradient(90deg, rgb(136, 131, 233) 0%, rgb(125, 125, 151) 0%, rgb(68, 143, 159) 100%);
  color: #fff;
  .pi {
    color: #fff;
  }
}
.saved-cards {
  .text-danger {
    margin-top: 0px !important;
    margin-bottom: 10px;
  }
}
.selected-card {
  outline-offset: 4px;
  outline: 3px solid #009cf7;
}
.bi-card-list {
  transform: scaleX(0.8);
}
.icon-building::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../images/building-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-sidebar::before {
  content: '';
  color: #7f8f9f;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../images/sidebar-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 0px;
}
.message-status {
  .icon-messages {
    font-size: 16px;
  }
  .icon-sidebar {
    display: inline-block;
    height: 100%;
  }
  .icon-sidebar,
  .icon-messages {
    position: relative;
  }
  .icon-window-filled {
    position: absolute;
    top: -2px;
    right: -3px;
    font-size: 10px;
  }
}
.court-settings-form {
  @media screen and (min-width: 768px) {
    height: calc(100vh - 115px);
    max-height: auto !important;
  }
}
.court-form-contents {
  @media screen and (min-width: 768px) {
    height: calc(100vh - 225px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.court-settings-preview {
  height: 500px;
  @media screen and (min-width: 768px) {
    height: calc(100vh - 115px);
    max-height: auto !important;
  }
  iframe {
    width: 100% !important;
  }
}
.court-setting-modal {
  @media screen and (max-width: 768px) {
    .p-dialog-content {
      overflow-y: auto !important;
    }
  }
}

@media (min-width: 990px) {
  .call-notes-ellipsis-text {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 120px !important;
  }
}

@media (min-width: 990px) and (max-width: 1500px) {
  .call-notes-ellipsis-text {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 80px !important;
  }
}
// .sidebar-height{
//   height: calc(100vh - 215px);
// }
.sidebar {
  min-height: calc(100vh - 290px);
  max-height: calc(100vh - 40px);
  height: auto;
}
.sidebar-list {
  height: auto !important;
  max-height: calc(100vh - 250px) !important;
  min-height: 200px !important;
}
.sidebar {
  .sidebar-list {
    .msg-container {
      height: auto !important;
    }
  }
}

@media (min-width: 990px) {
  .contact-ellipsis-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 200px !important;
  }
}

.firm-users-more-button {
  margin-left: 5px !important;
  display: inline-flex;
  align-items: center;
  line-height: normal;
}

.icon-disable {
  opacity: 0.5;
}

.discrepancy-tab {
  .p-tabview-nav-content {
    margin-bottom: 14px;
  }
}
.discrepancy-button {
  border: 1px solid $primary-main !important;
  padding: 0.5rem !important;
  border-radius: 10px !important;
  width: 34px;
  height: 34px;
  .bi-magic {
    margin-left: 1px;
    margin-top: -2px !important;
    display: block;
  }
  i {
    &::before {
      color: $primary-dark !important;
    }
  }
}

.discrepancy-files-tooltip {
  max-width: 400px !important;
  span {
    white-space: normal !important;
  }
}

.existing-files {
  .p-multiselect-token-label {
    max-width: -webkit-fill-available;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .p-multiselect-token {
    max-width: -webkit-fill-available;
  }
}

.mail-ai-tags {
  display: inline-flex;
  align-items: center;
  width: auto;
  white-space: nowrap;
  overflow: visible;
  .p-tag-value {
    display: inline-flex;
    align-items: center;
    width: auto;
    white-space: nowrap;
    overflow: visible;
  }
}

.b-tag {
  background-color: #eff4ff !important;
  border-radius: 8px !important;

  .p-tag-value {
    color: #2655b9 !important;
    font-weight: 500 !important;
  }
}
.stage-popup {
  .p-button.delete-btn {
    color: $icon-red !important;
  }
  .p-button {
    color: $primary-middle !important;
    font-weight: 600;
    .fa-chevron-left::before,
    .fa-chevron-right::before,
    .fa-edit::before {
      color: $primary-middle !important;
    }
    .fa-trash-alt::before {
      color: $icon-red;
    }
  }
}

template-title-table {
  @media screen and (min-width: 1021px) {
    width: 40%;
  }
}
.modal-mail {
  @media screen and (min-width: 1021px) {
    width: 50vw !important;
  }
  .p-dialog-content {
    .mail-preview-inner {
      box-shadow: none !important;
      border: none !important;
      padding: 0px auto;
      background-color: #fafbfe !important;
      margin-top: 14px;
    }
  }
}
@media screen and (min-width: 768px) {
  .ai-file-selection {
    max-height: calc(100vh - 185px);
    overflow: hidden;

    .container-fluid {
      overflow-y: auto;
    }
  }
  .file-directory-tree {
    height: calc(100vh - 220px);
  }
}
.timeline-container {
  .p-datatable-emptymessage {
    td {
      text-align: center !important;
    }
  }
  .datatable-responsive {
    padding: 0px;
  }
  @media screen and (min-width: 768px) {
    .timeline-table {
      height: calc(100vh - 300px);
      overflow-y: auto;
    }
  }
}

//FONT FAMILY,COLOR UPDATES
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.case-details-wrap,
.task-main-container {
  font-family: 'Inter', sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;

  h1.title,
  .title.h1 {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600;
  }
  .p-button-primary {
    font-family: 'Inter', sans-serif !important;
    background-color: #3457a3 !important;
    border: 1px solid #3457a3 !important;
  }
  .text-primary-dark {
    color: #1b3d86 !important;
    font-weight: 600;
    font-family: 'Inter', sans-serif !important;
  }
  .p-datatable .p-datatable-tbody > tr {
    color: #292a2d !important;
    font-family: 'Inter', sans-serif !important;
  }
  .p-button.button-text {
    color: #345497 !important;
  }
  .p-column-title {
    color: #48515b !important;
  }
  .p-column-title {
    // font-family: "Inter", sans-serif !important;
  }
  .p-multiselect .p-multiselect-label.p-placeholder,
  .case-details-wrap .input-search,
  .p-component,
  .p-component .p-tag-value,
  .task-heading {
    font-family: 'Inter', sans-serif !important;
  }
  .text-bold,
  .task-heading {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
  }
  .task-heading {
    color: $black-700;
  }
  //  {
  //     font-family: "Inter", sans-serif !important;
  //   }
  // .p-component{
  //   font-family: "Inter", sans-serif !important;
  // }
  // .p-component .p-tag-value{
  //   font-family: "Inter", sans-serif !important;
  // }
}
//---- FOR FONT CHANGE DEMO - NAVBAR INSIDE CASE ----
.case-details-page .navbar {
  background-color: white !important;
  .navbar-nav .nav-link {
    font-family: 'Inter', sans-serif !important;
    color: #333c47 !important;
  }
  .navbar-nav .active .nav-link {
    color: #2655b9 !important;
  }
  .p-button-label {
    font-family: 'Inter', sans-serif !important;
  }
  label {
    font-family: 'Inter', sans-serif !important;
  }
}
//---- FOR FONT CHANGE DEMO - NAVBAR INSIDE CASE ----

.task-details-page .navbar {
  background-color: white !important;
  .navbar-nav .nav-link {
    font-family: 'Inter', sans-serif !important;
    color: #333c47 !important;
  }
  .navbar-nav .active .nav-link {
    color: #2655b9 !important;
  }
  .p-button-label {
    font-family: 'Inter', sans-serif !important;
  }
  label {
    font-family: 'Inter', sans-serif !important;
  }
}

.task-main-container {
  .secondary-button-grey {
    border: 1px solid $black-600 !important;
    color: $black-600 !important;
  }
  .p-inputtext,
  .tag {
    font-family: 'Inter', sans-serif !important;
  }
  .task-wrap .task-sub-head {
    color: $black-600 !important;
  }
}
.bi-credit-card-2-back {
  vertical-align: 1px;
  &::before {
    font-size: 14px;
  }
}
.intuit-payment-card {
  border: 1px solid #e0e0e0;
  background-color: #fff;
  padding: 20px;
  margin-top: 14px;
  margin-bottom: 10px;
  border-radius: 16px;
}

// fax page
.fax-details-modal .fax-detail-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.fax-details-modal .status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: capitalize;
}

.fax-details-modal .status-received {
  background-color: #e3f2fd;
  color: #0d47a1;
}

.fax-details-modal .status-delivered {
  background-color: #e8f5e9;
  color: #1b5e20;
}

.fax-details-modal .status-failed {
  background-color: #ffebee;
  color: #b71c1c;
}

.fax-details-modal .status-queued {
  background-color: #fff8e1;
  color: #ff6f00;
}

.fax-details-modal .file-preview-card {
  height: 100%;
}

.fax-details-modal .file-preview-container {
  overflow: hidden;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.fax-details-modal .pdf-container {
  height: 100%;
  width: 100%;
}

.fax-details-modal .timeline-event {
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 8px;
}

.fax-details-modal .no-preview {
  background-color: #f5f5f5;
  color: #757575;
  font-style: italic;
}

.m-badge {
  position: inline-block;
  font-size: 8px !important;
  width: auto;
  min-width: 15px !important;
  padding: 2px 3px !important;
  height: 15px !important;
  margin-left: 10px;
  margin-bottom: 15px;
  line-height: normal !important;
  background: $primary-dark !important;
}

.m-badge {
  position: inline-block;
  font-size: 8px !important;
  width: auto;
  min-width: 15px !important;
  padding: 2px 3px !important;
  height: 15px !important;
  margin-left: 10px;
  margin-bottom: 15px;
  line-height: normal !important;
  background: $primary-dark !important;
}
